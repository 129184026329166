import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MainHeader from './components/mainHeader';
import Sidebar from './components/sidebar';
import LoginPage from "./pages/login";
import HomePage from "./pages/home";
import CabinetsPage from "./pages/cabinets";
import { NovoProvider } from './context/novo';
import { ToastContainer } from 'react-toastify';
import GameMixesPage from './pages/gameMixes';
import NewsPage from './pages/news';
import GalleryPage from './pages/gallery';
import Games from './pages/games';
import Categories from './pages/categories';
import Platforms from './pages/platforms';
import AuthGuard from './auth';
import PreviewPage from './pages/previewPage';

const PageContainer = styled('div')(() => ({
  padding: '88px 24px 24px'
}));

const App = () => {
  const [open, setOpen] = useState(false);

  return (
    <BrowserRouter>
      <NovoProvider>
        <ToastContainer theme='colored' />
        <Box sx={{ display: 'flex' }}>
          <MainHeader open={open} setOpen={setOpen} />
          <Sidebar open={open} setOpen={setOpen} />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <PageContainer className="page-container" sx={{ backgroundColor: "#FAF8FF", minHeight: '100vh' }} >
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path="/cabinets" element={<AuthGuard><CabinetsPage /></AuthGuard>} />
                <Route path="/gameMixes" element={<AuthGuard><GameMixesPage /></AuthGuard>} />
                <Route path="/news" element={<AuthGuard><NewsPage /></AuthGuard>} />
                <Route path="/gallery" element={<AuthGuard><GalleryPage /></AuthGuard>} />
                <Route path="/home" element={<AuthGuard><HomePage /></AuthGuard>} />
                <Route path="/games" element={<AuthGuard><Games /></AuthGuard>} />
                <Route path="/categories" element={<AuthGuard><Categories /></AuthGuard>} />
                <Route path="/platforms" element={<AuthGuard><Platforms /></AuthGuard>} />
                <Route path="/preview" element={<AuthGuard><PreviewPage /></AuthGuard>} />
              </Routes>
            </PageContainer>
          </Box>
        </Box>
      </NovoProvider>
    </BrowserRouter >
  );
};

export default App;
