import { Box, Card, Grid, IconButton } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import PlatformDialog from "./PlatformDialog";

const PlatformCard = ({ data, loadPlatforms }) => {
  const [isEditDialogOpened, setIsEditDialogOpened] = useState(false);


  return (
    <Card elevation={4} sx={{ padding: "10px", height: "100%" }}>
      <Grid container justifyContent="space-between" direction='column' sx={{ height: '100%' }}>
        <Grid item xs container justifyContent="left" padding="10px">
          <Grid item>
            <Box sx={{ width: "50px" }}>
              <img src={process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + data.media.src} alt="" style={{ width: "100%" }}></img>
            </Box>
          </Grid>
          <Grid item sx={{ paddingLeft: "10px" }} xs>
            <p style={{ margin: 0 }}>{data.name}</p>
          </Grid>
        </Grid>
        <Grid item xs="auto" container sx={{ borderTop: "1px solid #ddd", pt: 1 }} alignItems="left">
          <Grid item>
            <IconButton component="label" onClick={() => setIsEditDialogOpened(true)}>
              <EditIcon sx={{ color: "#2F2848" }} fontSize='medium' />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <PlatformDialog action="edit" data={data} isDialogOpened={isEditDialogOpened} setIsDialogOpened={setIsEditDialogOpened} loadPlatforms={loadPlatforms} />
    </Card>
  )
}
export default PlatformCard