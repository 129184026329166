export const createPreviewApi = (fetchApi) => {

  function getElementsForPublish() {
    return fetchApi(`/elements`, 'GET');
  }

  function publishChanges() {
    return fetchApi(`/publish`, 'POST');
  }

  return {
    getElementsForPublish,
    publishChanges
  };
};