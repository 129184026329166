import { Box, Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { useState } from "react";
import Crop from "./Crop";

const ImageCropper = ({ uploadedImage, setUploadedImage, setImageFile, setImageSmallFile, setIsCropMode, croppedImage, setCroppedImage, croppedSmallImage, setCroppedSmallImage, setIsImageCropped }) => {

  const [cropType, setCropType] = useState('large');
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const btnStyle = { backgroundColor: "#2F2848", "&:hover": { backgroundColor: "#443A69" } }

  const resetData = () => {
    setUploadedImage(null);
    setCroppedImage(null);
    setImageFile(null);
    setImageSmallFile(null);
    setCroppedSmallImage(null);
    setCropType('large');
    setIsDialogOpened(false);
    setIsCropMode(false);
    setIsImageCropped(true);
  }

  const finishCrop = () => {
    setUploadedImage(null); 
    setIsCropMode(false); 
    setCropType("large"); 
    setIsImageCropped(true);
  }
  
  return (
    <>
      {uploadedImage && (
        <Box sx={{ borderTop: "1px solid #fff" }}>
          {cropType === "large" && (
            <>
              {uploadedImage &&
                <Crop
                  image={uploadedImage}
                  setImage={setUploadedImage}
                  setImageFile={setImageFile}
                  aspect={10 / 3}
                  croppedImage={croppedImage}
                  setCroppedImage={setCroppedImage}
                  title="<strong>Korak 1</strong>: Molimo vas isecite izabranu sliku za <u><strong>desktop</strong></u> verziju"
                  setIsCropMode={setIsCropMode}
                  setIsDialogOpened={setIsDialogOpened}
                />
              }
            </>
          )}
          {cropType === "small" && (
            <>
              {uploadedImage &&
                <Crop
                  image={uploadedImage}
                  setImage={setUploadedImage}
                  setImageFile={setImageSmallFile}
                  aspect={2 / 1}
                  croppedImage={croppedSmallImage}
                  setCroppedImage={setCroppedSmallImage}
                  title="<strong>Korak 2</strong>: Molimo vas isecite izabranu sliku za <u><strong>mobilnu</strong></u> verziju"
                  setIsCropMode={setIsCropMode}
                  setIsDialogOpened={setIsDialogOpened}
                />
              }
            </>
          )}
          {croppedImage && (
            <Grid container justifyContent="space-between" p={2}>
              <Grid item>
                {cropType === "small" && (
                  <Button variant='contained' sx={btnStyle} onClick={() => setCropType(cropType === "small" ? "large" : "small")}>Nazad</Button>
                )}
              </Grid>
              <Grid item>
                {cropType === "large" && (
                  <Button variant='contained' sx={btnStyle} onClick={() => setCropType('small')}>Dalje</Button>
                )}
                {cropType === "small" && croppedSmallImage && (
                  <Button variant='contained' sx={btnStyle} onClick={finishCrop}>Završi</Button>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      <Dialog open={isDialogOpened}>
        <DialogContent>Da li ste sigurni da želite da poništite podešavanja slike?</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpened(false)} sx={{ color: "#2F2848" }}>Odustani</Button>
          <Button onClick={resetData} variant='contained' sx={{ backgroundColor: "#2F2848", "&:hover": { backgroundColor: "#443A69" } }}>Poništi</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default ImageCropper