import { Button, Grid } from "@mui/material"
import PlatformCard from "../../components/Platforms/PlatformCard"
import { useEffect, useState } from "react"
import PlatformDialog from "../../components/Platforms/PlatformDialog"
import { api } from "../../services";

const Platforms = () => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [isPreLoaded, setIsPreLoaded] = useState(false);

  const buttonStyle = {
    backgroundColor: "#2f2848",
    color: "#fff",
    border: "1px solid #2f2848",
    "&:hover": {
      color: "#2f2848"
    },
    mb: 3
  }

  const fetchplatforms = () => {
    api.platforms.getPlatforms().then(res => {
      if (!res) return;
      setPlatforms(res.reverse());
    })
  };

  useEffect(() => {
    setIsPreLoaded(true)
  }, []);

  useEffect(() => {
    setPlatforms(platforms);
  }, [platforms]);

  useEffect(() => {
    if (isPreLoaded) fetchplatforms();
  }, [isPreLoaded]);


  return (
    <>
      <Grid container alignItems="left">
        <Grid item>
          <Button onClick={() => setIsDialogOpened(true)} sx={buttonStyle}>Dodaj novu platformu</Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {(platforms || []).map((item, key) => (
          <Grid xs={12} sm={6} md={5} lg={4} xl={3} item key={key}>
            <PlatformCard data={item} platforms={platforms} setPlatforms={setPlatforms} loadPlatforms={fetchplatforms}/>
          </Grid>
        ))}
      </Grid>
      <PlatformDialog action="add" isDialogOpened={isDialogOpened} setIsDialogOpened={setIsDialogOpened} loadPlatforms={fetchplatforms}/>
    </>
  )
}

export default Platforms