import { Box, Button, Card, Grid, Tooltip, Typography } from "@mui/material"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ImageCropper from "../Crop/ImageCropper";
import EditIcon from '@mui/icons-material/Edit';
import { useRef, useState } from "react";

const CoverImageCard = ({ errors, imageFile, setImageFile, setImageSmallFile, action, formValues, setIsImageCropped }) => {
  const imageRef = useRef();
  const [uploadedCoverImage, setUploadedCoverImage] = useState(null);
  const [isCropMode, setIsCropMode] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedSmallImage, setCroppedSmallImage] = useState(null);

  const ERROR_COLOR = "#B52828"

  const uploadImage = (ref) => {
    if (imageFile) setImageFile(null);
    setUploadedCoverImage(URL.createObjectURL(ref.current.files[0]));
    setIsCropMode(true);
    setIsImageCropped(false);
  }

  return (
    <Card elevation={4}>
      <Box sx={{ p: 2, pr: 0, transition: "0.5s", backgroundColor: errors.coverImage ? ERROR_COLOR : "#2F2848", color: "#fff" }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">*Cover slike</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Dodajte sliku">
              <Button component="label" fullWidth sx={{ p: 0 }}>
                <input
                  required
                  hidden
                  name="large"
                  type="file"
                  accept=".png, .jpg, .jpeg, .webp"
                  ref={imageRef}
                  onInput={() => uploadImage(imageRef)}
                  onClick={(event) => { event.target.value = null }}
                />
                {action === "add" && !isCropMode && (
                  <>
                    {!imageFile && <AddCircleIcon fontSize="large" sx={{ color: "#fff", p: 0 }} />}
                  </>
                )}
                {(imageFile || formValues['cover_image']) && <EditIcon fontSize="large" sx={{ color: "#fff", p: 0 }} ></EditIcon>}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <ImageCropper
        uploadedImage={uploadedCoverImage}
        setUploadedImage={setUploadedCoverImage}
        setImageFile={setImageFile}
        setImageSmallFile={setImageSmallFile}
        setIsCropMode={setIsCropMode}
        croppedImage={croppedImage}
        setCroppedImage={setCroppedImage}
        croppedSmallImage={croppedSmallImage}
        setCroppedSmallImage={setCroppedSmallImage}
        setIsImageCropped={setIsImageCropped}
      />
      {!isCropMode && (
        <Box>
          {(croppedImage || (formValues && formValues['cover_image'])) && (
            <Box sx={{ p: 2 }}>
              <Typography fontSize={18}><strong>1. Desktop:</strong></Typography>
              <Box sx={{ textAlign: "center" }}>
                <img style={{ maxHeight: '100%', maxWidth: '100%', padding: "5px", marginTop: "15px" }} src={croppedImage || (formValues['cover_image'] && `https://api.novoinvestment.rs/${formValues['cover_image'].src}`)} alt="" />
              </Box>
            </Box>
          )}
          {(croppedSmallImage || (formValues && formValues['cover_image_small'])) && (
            <Box sx={{ p: 2 }}>
              <Typography fontSize={18}><strong>2. Mobile:</strong></Typography>
              <Box sx={{ textAlign: "center" }}>
                <img style={{ maxHeight: '100%', maxWidth: '70%', padding: "5px", marginTop: "15px" }} src={croppedSmallImage || (formValues['cover_image_small'] && `https://api.novoinvestment.rs/${formValues['cover_image_small'].src}`)} alt="" />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Card>
  )
}
export default CoverImageCard