import { Box, Card, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from "@mui/material";
import ReactQuill from "react-quill";
// import { formatDate } from '../../utils/validation';
import { useEffect, useState } from "react";

const BasicInfoCard = ({ errors, formValues, setFormValues, isNew, setIsNew, isComingSoon, setIsComingSoon, type, action }) => {
  const modules = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        "strike",
        // "blockquote",
        "link",
      ]
    ],
  }
  const [newDescription, setNewDescription] = useState(formValues.description);

  const ERROR_COLOR = "#B52828"

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    })
  }

  const dateNow = new Date(); 
  const year = dateNow.getFullYear(); 
  const monthWithOffset = dateNow.getUTCMonth() + 1; 
  const month = 
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2 
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  const handleDescription = (event) => {
    setNewDescription(event)
  }

  const handleFlag = (event) => {
    const { name, checked } = event.target;
    switch (name) {
      case "new": setIsNew(checked); break;
      case "soon": setIsComingSoon(checked); break;
      default: break
    }
  }
  
  useEffect(() => {
    setFormValues({...formValues, description: newDescription})
  }, [newDescription])

  return (
    <Card elevation={4}>
      <Box sx={{ p: 2, transition: "0.5s", backgroundColor: errors.basicInfo ? ERROR_COLOR : "#2F2848", color: "#fff" }}>
        <Typography variant="h5">*Opšte informacije</Typography>
      </Box>
      <Box sx={{ px: 2, py: 3 }}>
        {type === "news" ? (
          <TextField label="Naslov" variant="outlined" fullWidth required type="text" name="title" onChange={handleChange} value={formValues.title ? formValues.title : ""} />
        ) : (
          <TextField label="Naslov" variant="outlined" fullWidth required type="text" name="name" onChange={handleChange} value={formValues.name ? formValues.name : ""} />
        )}
        {type === "news" && (
          <>
            <TextField label="Podnaslov" variant="outlined" fullWidth type="text" name="subtitle" onChange={handleChange} value={formValues.subtitle ? formValues.subtitle : ""} sx={{ my: 2 }} />
            <TextField disabled={action === "edit"} label="Datum" type="date" name="date" value={formValues.date ? formValues.date : new Date()} required onChange={handleChange}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                inputProps: {
                  min: materialDateInput
                }
              }}
            />
          </>
        )}
        <Box className="quillContainer" mt={2} sx={{ border: errors.description ? `1px solid #D32F2F` : "", borderRadius: "5px" }}>
          <ReactQuill
            required
            theme="snow"
            placeholder="Unesite tekst za OPIS"
            modules={modules}
            value={newDescription ? newDescription : formValues.description}
            onChange={handleDescription}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormGroup row>
            <FormControlLabel control={<Checkbox name="new" disabled={isComingSoon} checked={isNew ? true : false} inputProps={{ 'aria-label': 'controlled' }} onChange={handleFlag} />} label="New" />
            <FormControlLabel control={<Checkbox name="soon" disabled={isNew} checked={isComingSoon ? true : false} inputProps={{ 'aria-label': 'controlled' }} onChange={handleFlag} />} label="Coming soon" />
          </FormGroup>
        </Box>
      </Box>
    </Card>
  )
}
export default BasicInfoCard