import { Box, Card, Grid, Typography } from "@mui/material";
import { formatDate } from "../../utils/validation";

const PreviewCard = ({ title, data, type }) => {
  return (
    <Card elevation={4}>
      <Box sx={{ px: 2, py: 2, backgroundColor: "#2F2848", color: "#fff" }}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Grid container justifyContent="space-between" sx={{ borderBottom: "1px solid #ddd", py: 1 }}>
          <Grid item xs>
            <Typography><strong>{type === "gallery" ? "Slika" : "Naziv"}</strong></Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography><strong>Datum izmene</strong></Typography>
          </Grid>
        </Grid>
        {data.map((item, key) => (
          <Grid container justifyContent="space-between" alignItems="center" key={key} sx={{ borderTop: key > 0 ? "1px solid #ddd" : "", py: 1 }}>
            <Grid item xs>
              {type === "gallery" ? (
                <img src={process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.thumbnail} alt="" style={{ maxWidth: "100px"}}></img>
              ) : (
                <Typography pr={2}>{item.name || item.title}</Typography>
              )}

            </Grid>
            <Grid item xs="auto">
              <Typography>{formatDate(item.updated_at.split(' ')[0])}</Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Card>
  )
}
export default PreviewCard