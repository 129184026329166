import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import { DRAWER_WIDTH } from "../sidebar";
import { NovoContext, NovoContextType } from "../../context/novo";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import { api } from "../../services";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { t } from "i18next";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MainHeader = ({ open, setOpen }) => {
  const [isMenuOpen, setIsMenuOpened] = useState(false);
  const [isPreLoaded, setIsPreLoaded] = useState(false);
  const { state, dispatch } = useContext(NovoContext);
  const navigate = useNavigate();

  const logout = () => {
    api.users.logout();
    sessionStorage.removeItem("token");
    dispatch({
      type: NovoContextType.SET_USER,
      data: null,
    });
    navigate("/login")
  };

  useEffect(() => {
    setIsPreLoaded(true);
  }, []);

  return (
    <AppBar sx={{ backgroundColor: "#2f2848" }} position="fixed" open={open}>
      <Toolbar>
        {sessionStorage.getItem("token") && (
          <IconButton color="inherit" onClick={() => setOpen(true)} edge="start" sx={{ marginRight: 5, ...(open && { display: "none" }) }}>
            <MenuIcon />
          </IconButton>
        )}
        {!sessionStorage.getItem("token") ? (
          <Button variant="outlined" color="inherit" startIcon={<LoginIcon />} onClick={() => navigate("/login")} sx={{ marginLeft: "auto" }}>
            {t('login')}
          </Button>
        ) : (
          <Button
            edge="end"
            variant="outlined"
            color="inherit"
            startIcon={<LogoutIcon fontSize="5px" sx={{ color: "#fff" }} />}
            onClick={() => logout()}
            sx={{ marginLeft: "auto" }}
          >
            {t('logout')}
          </Button>
        )}
      </Toolbar>
      {state.user && (
        <Menu
          anchorPosition={{ left: 10000, top: 0 }}
          open={isMenuOpen}
          disableAutoFocusItem
          onClose={() => setIsMenuOpened(false)}
          anchorReference={'anchorPosition'}
        >
          <Box sx={{ textAlign: "center", padding: "10px 20px 0" }}>
            <Avatar src={state.user?.avatarUri} sx={{ margin: "auto" }} />
            <Typography name="name" sx={{ color: "#2f2848", fontWeight: "bold", marginTop: "10px" }}>
              {state.user.firstName} {state.user.lastName}
            </Typography>
            {state.user.cityName && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <LocationOnIcon sx={{ color: "#2f2848", fontSize: "16px" }} />
                <Typography name="city" sx={{ fontSize: "12px", paddingLeft: "5px" }}>
                  {state.user?.cityName}
                </Typography>
              </Box>
            )}
            {state.user.phoneNumber && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <LocalPhoneIcon sx={{ color: "#2f2848", fontSize: "16px" }} />
                <Typography name="phoneNumber" sx={{ fontSize: "12px", paddingLeft: "5px" }}>
                  +{state.user.phoneCountryCode}
                  {state.user.phoneNumber}
                </Typography>
              </Box>
            )}

          </Box>
        </Menu>
      )}
    </AppBar>
  );
};

export default MainHeader;
