import { Box, Card, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { api } from "../../services";

const CategoriesCard = ({errors, selectedCategoryId, setSelectedCategoryId}) => {
  const [categories, setCategories] = useState(null);

  const ERROR_COLOR = "#B52828"

  const selectCategory = (category) => {
    setSelectedCategoryId(category.id)
  }

  const fetchCategories = () => {
    api.categories.getNewsCategories().then(res => {
      if (!res) return;
      setCategories(res)
    })
  }

  useEffect(() => {
    if (!categories) fetchCategories();
  }, [])

  return (
    <Card elevation={4} sx={{ mt: 4 }}>
      <Box sx={{ p: 2, transition: "0.5s", backgroundColor: errors.categories ? ERROR_COLOR : "#2F2848", color: "#fff" }}>
        <Typography variant="h5">*Kategorije:</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          {categories && categories.map((item, key) => (
            <FormControlLabel value={item.title} control={<Radio />} label={item.title} checked={item.id === selectedCategoryId} onChange={() => selectCategory(item)} key={key} />
          ))}
        </RadioGroup>
      </Box>
    </Card>
  )
}
export default CategoriesCard