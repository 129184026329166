import { Box, Button, Grid, IconButton, InputBase } from "@mui/material"
// import gamesData from "../../data/GamesData.json"
import GamesCard from "../../components/Games/GamesCard"
import { useEffect, useState } from "react"
import GameDialog from "../../components/Games/GameDialog"
import { GridSearchIcon } from "@mui/x-data-grid"
import { api } from "../../services";

const Games = () => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [games, setGames] = useState([]);
  const [formatedGames, setFormatedGames] = useState();
  const [isPreLoaded, setIsPreLoaded] = useState(false);

  const buttonStyle = {
    backgroundColor: "#2f2848",
    color: "#fff",
    border: "1px solid #2f2848",
    "&:hover": {
      color: "#2f2848"
    },
    mb: 3
  }

  const fetchGames = () => {
    api.games.getGames().then(res => {
      if (!res) return;
      setGames(res.reverse());
    })
  };

  useEffect(() => {
    setIsPreLoaded(true)
  }, []);

  useEffect(() => {
    setFormatedGames(games);
  }, [games]);

  useEffect(() => {
    if (isPreLoaded) fetchGames();
  }, [isPreLoaded]);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setFormatedGames(games.filter(g => g.name.toLowerCase().includes(value)))
  }

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Button onClick={() => setIsDialogOpened(true)} sx={buttonStyle}>Dodaj novu igru</Button>
        </Grid>
        <Grid item>
          <Box>
            <InputBase
              id="searchField"
              sx={{ ml: 1, flex: 1, borderBottom: "1px solid #757575" }}
              placeholder="Pretraži igricu"
              onChange={handleSearch}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <GridSearchIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {(formatedGames || []).map((item, key) => (
          <Grid xs={12} sm={6} md={5} lg={4} xl={3} item key={key}>
            <GamesCard data={item} games={games} setGames={setGames} loadGames={fetchGames}/>
          </Grid>
        ))}
      </Grid>
      {!(formatedGames || []).length && (
        <Box sx={{ fontSize: "20px", textAlign: "center", marginTop: "50px" }}>Igra nije pronadjena</Box>
      )}
      <GameDialog action="add" isDialogOpened={isDialogOpened} setIsDialogOpened={setIsDialogOpened} loadGames={fetchGames}/>
    </>
  )
}

export default Games