export const createGameApi = (fetchApi) => {

  function getGame(id) {
    return fetchApi(`/game/${id}`, 'GET');
  }

  function getGames() {
    return fetchApi(`/games`, 'GET');
  }

  function addGame(data) {
    return fetchApi(`/game`, 'POST', data);
  }

  function updateGame(id, data) {
    return fetchApi(`/game/${id}`, 'POST', data);
  }

  function deleteGame(id) {
    return fetchApi(`/game/${id}`, 'DELETE');
  }

  return {
    getGame,
    getGames,
    addGame,
    deleteGame,
    updateGame
  };
};