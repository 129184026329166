const validateForm = (form) => {
  let isValid = true;
  form.querySelectorAll('[required]').forEach(elem => {
    if (elem.type !=="file" && !elem.value ) {
      const parent = elem.parentElement;
      if(parent.previousSibling) parent.previousSibling.classList.add('Mui-error');
      parent.classList.add('Mui-error');
      isValid = false;
    }
  });
  return isValid;
}

export const validatePhoneNumber = (form) => {
  let isValid = true;
  const validPattern = /^\d{11,12}$/;
  const elem = form.querySelector("#phoneNumber");
  if (!validPattern.test(elem.value)) {
    const parent = elem.parentElement;
    if(parent.previousSibling) parent.previousSibling.classList.add('Mui-error');
    parent.classList.add('Mui-error');
    isValid = false;
  }
  return isValid;
}

export const validateNationalId = (form) => {
  let isValid = true;
  const validPattern = /^[A-Z0-9]{14}$/;
  const element = form.querySelector("#nationalId");
  if (!validPattern.test(element.value)) {
    const parent = element.parentElement;
    parent.previousSibling.classList.add('Mui-error');
    parent.classList.add('Mui-error');
    isValid = false;
  }
  return isValid;
}

export const formatDate = (date) => {
  const dateSplited = date.split('-');
  const day = dateSplited[2]
  const month = dateSplited[1]
  const year = dateSplited[0]

  return `${day}.${month}.${year}.`
}

export const validateVideo = (form) => {
  let isValid = true;
  const validPattern = /^https?:\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/\S*)?\.mp4$/
  form.querySelectorAll(".video-link input").forEach(item => {
    if(!validPattern.test(item.value)) {
      item.classList.add('Mui-error');
      isValid = false;
    }
  })
  return isValid;
}

export default validateForm;