import { Box, Card, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { api } from "../../services";

const GameMixCard = ({errors, formValues, setFormValues}) => {
  const [gameMixes, setGameMixes] = useState([]);

  const ERROR_COLOR = "#B52828"

  const selectGameMix = (event, gameMix) => {
    const gameMixId = Number(gameMix.id)
    const checked = event.target.checked
    if (checked) {
      const selectedgameMixes = formValues.gameMixIds ? [...formValues.gameMixIds, gameMixId] : [gameMixId]
      const gameMixes = [...new Set(selectedgameMixes)]
      setFormValues({ ...formValues, gameMixIds: gameMixes })
    }
    else {
      setFormValues({ ...formValues, gameMixIds: (formValues.gameMixIds || []).filter(id => id !== gameMixId) })
    }
  }

  const fetchGameMixes = () => {
    api.gameMixes.getGameMixes().then(res => {
      setGameMixes(res);
    })
  }

  useEffect(() => {
    fetchGameMixes();
  }, [])

  return (
    <Card elevation={4} sx={{ mt: 4 }}>
      <Box sx={{ p: 2, transition: "0.5s", backgroundColor: errors.gameMixes ? ERROR_COLOR : "#2F2848", color: "#fff" }}>
        <Typography variant="h5">*Miksevi igara</Typography>
      </Box>
      <Grid sx={{ p: 2 }} container>
        {gameMixes.map((item, key) => (
          <Grid key={key} item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={((formValues || {}).gameMixIds || []).includes(item.id)}
                  onChange={(event) => selectGameMix(event, item)}
                />
              }
              label={item.name} />
          </Grid>)
        )}
      </Grid>
    </Card>
  )
}
export default GameMixCard