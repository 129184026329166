import React, { forwardRef, useEffect, useState } from "react";
import { Box, Button, Dialog, Slide } from "@mui/material";
import Item from "../../components/Item"
import Grid from "@mui/material/Grid";
import GameMixesEditor from "./gameMixesEditor";
import { api } from '../../services';
import { toast } from "react-toastify";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GameMixesPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gameMixes, setGameMixes] = useState([]);
  const [isPreLoaded, setIsPreLoaded] = useState(false);

  const fetchGameMixes = () => {
    api.gameMixes.getGameMixes().then(res => {
      if (!res) return;
      setGameMixes(res)
      // setGameMixes(res.filter(gm => gm.status === "active"))
    })
  }
  
  const deleteGameMix = (gameMixId) => {
    api.gameMixes.updateGameMix(gameMixId, {status: "deleted"}).then(res => {
      if(!res) return;
      toast.success('Uspešno ste obrisali game mix', { autoClose: 2000 })
      fetchGameMixes();
    })
  }

  useEffect(() => {
    setIsPreLoaded(true);
  }, []);

  useEffect(() => {
    if (isPreLoaded) fetchGameMixes();
  }, [isPreLoaded])

  const buttonStyle = {
    backgroundColor: "#2f2848",
    color: "#fff",
    border: "1px solid #2f2848",
    "&:hover": {
      color: "#2f2848"
    }
  }

  return (
    <>
      <Grid item sx={{ mb: 3 }}>
        <Button onClick={() => setIsModalOpen(true)} sx={buttonStyle}>Dodaj novi miks igara</Button>
      </Grid>
      <Grid container spacing={3}>
        {gameMixes.map((item, key) => (
          <Grid xs={12} md={6} lg={4} item key={key}>
            <Item type="game-mix" data={item} loadData={fetchGameMixes} deleteItem={() => deleteGameMix(item.id)}/>
          </Grid>
        ))}
      </Grid>
      <Dialog open={isModalOpen} fullScreen TransitionComponent={Transition}>
        <Box sx={{ marginTop: { xs: "80px", md: "70px" } }}>
          <GameMixesEditor action="add" setIsModalOpen={setIsModalOpen} allData={gameMixes} setAllData={setGameMixes}></GameMixesEditor>
        </Box>
      </Dialog>
    </>
  )
}
export default GameMixesPage