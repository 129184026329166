import { Box, Button, Card, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Tooltip, Typography } from "@mui/material"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const InformationsCard = ({errors, facts, setFacts }) => {

  const ERROR_COLOR = "#B52828"

  const addNewFact = () => {
    setFacts([...facts, ""]);
  }

  const handleChangeFact = (event, name) => {
    const { value } = event.target;
    const temp = [...facts];
    temp[name] = value;
    setFacts([...temp]);
  }

  const removeFact = (key) => {
    const temp = [...facts];
    temp.splice(key, 1);
    setFacts([...temp]);
  }

  return (
    <Card elevation={4}>
      <Box sx={{ p: 2, pr: 0, transition: "0.5s", backgroundColor: errors.information ? ERROR_COLOR : "#2F2848", color: "#fff" }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">*Informacije</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Dodajte novu informaciju">
              <Button component="label" fullWidth sx={{ p: 0 }} onClick={addNewFact}>
                <AddCircleIcon fontSize="large" sx={{ color: "#fff", p: 0 }} />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      {!!(facts || []).length && (
        <Box sx={{ p: 2 }}>
          {(facts || []).map((item, key) => {
            return (
              <Box key={key}>
                <FormControl sx={{ my: 1 }} variant="outlined" fullWidth required
                >
                  <InputLabel htmlFor="outlined-adornment-password">Informacija</InputLabel>
                  <OutlinedInput
                    onChange={(event) => handleChangeFact(event, key)}
                    value={item}
                    startAdornment={
                      <InputAdornment position="start" >
                        <ArrowForwardIosIcon />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => removeFact(key)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    type={'text'}
                    label="Password"
                  />
                </FormControl>
              </Box>
            )
          })}
        </Box>
      )}
    </Card>
  )
}
export default InformationsCard