import React, { useContext, useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { AppBar } from "@mui/material";
import Button from '@mui/material/Button';
import 'react-quill/dist/quill.snow.css';
import validateForm, { validateVideo } from "../../utils/validation";
import { api } from "../../services";
import { toast } from "react-toastify";
import BasicInfoCard from "../../components/DeatilCards/BasicInfoCard";
import CoverImageCard from "../../components/DeatilCards/CoverImageCard";
import MediaCard from "../../components/DeatilCards/MediaCard";
import CabinetsCard from "../../components/DeatilCards/CabinetsCard";
import GamesCard from "../../components/DeatilCards/GamesCard";
import PlatformsCard from "../../components/DeatilCards/PlatformsCard";
import VideoCard from "../../components/DeatilCards/VideoCard";
import { NovoContext, NovoContextType } from "../../context/novo";

const GameMixesEditor = ({ action, gameMixId, setIsModalOpen, allData, setAllData, loadGameMixes }) => {
  const [formValues, setFormValues] = useState({});
  const formRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageSmallFile, setImageSmallFile] = useState(null);
  const [isPreLoaded, setIsPreLoaded] = useState(false);
  const [errors, setErrors] = useState({});
  const [isNew, setIsNew] = useState();
  const [isComingSoon, setIsComingSoon] = useState();
  const [selectedPlatformId, setSelectedPlatformId] = useState(null);
  const [videoLinks, setVideoLinks] = useState([]);
  const { dispatch } = useContext(NovoContext);
  const [isImageCropped, setIsImageCropped] = useState(true);

  const buttonStyle = {
    border: '1px solid white',
    transition: "0.5s",
    backgroundColor: "#2F2848",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#2F2848"
    }
  }

  const validateData = () => {
    let isValid = true;
    let _errors = {}
    validateForm(formRef.current)

    if (!formValues.name) {
      _errors = { ..._errors, basicInfo: true }
      isValid = false;
    }
    if (!formValues.description || formValues.description === "<p><br></p>") {
      _errors = { ..._errors, description: true, basicInfo: true }
      isValid = false;
    }
    if (!formValues.cabinetIds || !formValues.cabinetIds.length) {
      _errors = { ..._errors, cabinets: true }
      isValid = false;
    }
    if (!selectedPlatformId) {
      _errors = { ..._errors, platforms: true }
      isValid = false;
    }
    if ((action === "add" && !isImageCropped) || (action === "edit" && (!formValues.cover_image_small || !isImageCropped))) {
      _errors = { ..._errors, coverImage: true }
      isValid = false;
    }
    if (!formValues.gameIds || !formValues.gameIds.length) {
      _errors = { ..._errors, games: true }
      isValid = false;
    }
    if ((action === "add" && !imageFile) || (action === "edit" && !formValues.cover_image_small)) {
      _errors = { ..._errors, coverImage: true }
      isValid = false;
    }
    if (videoLinks.includes('')) {
      _errors = { ..._errors, video: true }
      isValid = false;
    }
    setErrors(_errors);
    return isValid;
  }

  const handleAction = () => {
    if (action === "add") addGameMix()
    else updateGameMix();
  }

  const formatFormData = (formData) => {
    formValues.name && formData.append('name', formValues.name);
    formData.append('description', formValues.description);
    imageFile && formData.append('cover_image', imageFile);
    imageSmallFile && formData.append('cover_image_small', imageSmallFile);
    formData.append('is_new', isNew ? 1 : 0);
    formData.append('is_coming_soon', isComingSoon ? 1 : 0);
    selectedPlatformId && formData.append('platform_ids[]', selectedPlatformId);
    if (formValues.gameIds) for (var i = 0; i < formValues.gameIds.length; i++) {
      formData.append('game_ids[]', formValues.gameIds[i]);
    }
    if (formValues.cabinetIds) for (var i = 0; i < formValues.cabinetIds.length; i++) {
      formData.append('cabinet_ids[]', formValues.cabinetIds[i]);
    }
    videoLinks && (videoLinks.length ? videoLinks.forEach(item => formData.append('video_links[]', item)) : formData.append('video_links[]', []));
    (formValues.media || []).length && formValues.media.forEach(item => {
      formData.append('media_ids[]', item.id)
    })
  }

  const addGameMix = () => {
    if (!validateData()) {
      toast.error("Popunite sva obavezna polja", { autoClose: 2000 });
      return;
    }
    if (!validateVideo(formRef.current)) {
      toast.error("Video link mora biti u formatu https://www.novomatic.com/xxx.mp4", { autoClose: 2000 });
      return;
    }

    const formData = new FormData();
    formatFormData(formData);
    api.gameMixes.addGameMix(formData).then(res => {
      if (!res) return;
      setIsModalOpen(false);
      setAllData([
        res,
        ...allData
      ])
      dispatch({
        type: NovoContextType.SET_BADGE,
        data: true,
      });
      toast.success('Uspešno ste kreirali Game Mix', { autoClose: 2000 })
    })
  }

  const updateGameMix = () => {
    if (!validateData()) {
      toast.error("Popunite sva obavezna polja", { autoClose: 2000 });
      return;
    }
    if (!validateVideo(formRef.current)) {
      toast.error("Video link mora biti u formatu https://www.novomatic.com/xxx.mp4", { autoClose: 2000 });
      return;
    }

    const formData = new FormData();
    formatFormData(formData);
    api.gameMixes.updateGameMix(gameMixId, formData).then(res => {
      if (!res) return;
      setIsModalOpen(false);
      loadGameMixes();
      dispatch({
        type: NovoContextType.SET_BADGE,
        data: true,
      });
      toast.success('Uspešno ste izmenili Game Mix.', { autoClose: 2000 })
    })
  }

  const fetchGameMix = () => {
    api.gameMixes.getGameMix(gameMixId).then(res => {
      setSelectedPlatformId(res.platforms[0].id);
      setFormValues({ ...res, gameIds: res.games.map(g => g.id), cabinetIds: res.cabinets.map(c => c.id) });
      setIsNew(res['is_new'] ? true : false);
      setIsComingSoon(res['is_coming_soon'] ? true : false);
      setVideoLinks(res.video_links || []);
    })
  }

  useEffect(() => {
    setIsPreLoaded(true)
  }, []);

  useEffect(() => {
    if (action === "edit") fetchGameMix();
  }, [action, isPreLoaded])

  return (
    <>
      <AppBar sx={{ position: 'fixed', backgroundColor: "#2F2848" }}>
        <Grid container sx={{ padding: "10px 10px 10px 20px" }} alignItems="center">
          <Grid item sx={{ flex: 1 }}>
            {formValues && <Typography variant="h6">{formValues.name ? formValues.name : ""}</Typography>}
          </Grid>
          <Grid item>
            <Button variant="outline" onClick={() => setIsModalOpen(false)} sx={{ mr: 1, "&:hover": {} }}>Odustani</Button>
            <Button variant="contained" onClick={handleAction} sx={buttonStyle}>Sačuvaj</Button>
          </Grid>
        </Grid>
      </AppBar>
      <Box sx={{ px: 3, py: 1, fontSize: 18 }}>Kartice označene * moraju biti popunjene</Box>
      <Grid container spacing={4} sx={{ p: 2 }} component="form" ref={formRef} noValidate>
        <Grid item container spacing={6} xs={12}>
          <Grid item xs={12} xl={6}>
            <Box>
              <BasicInfoCard
                errors={errors}
                formValues={formValues}
                setFormValues={setFormValues}
                isNew={isNew}
                setIsNew={setIsNew}
                isComingSoon={isComingSoon}
                setIsComingSoon={setIsComingSoon}
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <CabinetsCard
                errors={errors}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <GamesCard
                errors={errors}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Box>
            <Box>
              <PlatformsCard
                errors={errors}
                selectedPlatformId={selectedPlatformId}
                setSelectedPlatformId={setSelectedPlatformId}
              />
            </Box>
          </Grid>
          <Grid item container xs={12} xl={6} alignContent={"start"}>
            <Grid item xs={12}>
              <Box>
                <CoverImageCard
                  errors={errors}
                  imageFile={imageFile}
                  setImageFile={setImageFile}
                  imageSmallFile={imageSmallFile}
                  setImageSmallFile={setImageSmallFile}
                  action={action}
                  formValues={formValues}
                  setIsImageCropped={setIsImageCropped}
                />
              </Box>
              <Box sx={{ mt: 4 }}>
                <VideoCard
                  errors={errors}
                  videoLinks={videoLinks}
                  setVideoLinks={setVideoLinks}
                />
              </Box>
              <Box sx={{ mt: 4 }}>
                <MediaCard
                  action={action}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default GameMixesEditor;