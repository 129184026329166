import { Box, Button, Card, Dialog, DialogActions, DialogContent, Grid, Tooltip, Typography } from "@mui/material"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useRef, useState } from "react";
import Crop from "../Crop/Crop";
import { api } from "../../services";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const MediaCard = ({ formValues, setFormValues }) => {

  const imageRef = useRef();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isCropMode, setIsCropMode] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [mediaFile, setMediaFile] = useState(null);

  const btnStyle = { backgroundColor: "#2F2848", "&:hover": { backgroundColor: "#443A69" } }

  const uploadImage = (ref) => {
    if (mediaFile) setMediaFile(null);
    setUploadedImage(URL.createObjectURL(ref.current.files[0]));

    setIsCropMode(true);
  }

  const resetData = () => {
    setUploadedImage(null);
    setCroppedImage(null);
    setIsDialogOpened(false);
    setIsCropMode(false);
  }

  const addMedia = () => {
    const formData = new FormData();
    formData.append('image', mediaFile);
    formData.append('type', "cabinet");

    api.media.uploadMedia(formData).then(res => {
      if (!res) return;
      if (formValues.media) {
        setFormValues({
          ...formValues,
          media: [...formValues.media, res]
        })
      }
      else {
        setFormValues({
          ...formValues,
          media: [res]
        })
      }
    })
    resetData();
  }

  const deleteMedia = (event, mediaid) => {
    setFormValues({
      ...formValues,
      media: formValues.media.filter(item => item.id !== mediaid)
    })
  }

  return (
    <Card elevation={4} sx={{ mt: 4 }}>
      <Grid container justifyContent="space-between" sx={{ p: 2, pr: 0, transition: "0.5s", backgroundColor: "#2F2848", color: "#fff" }}>
        <Grid item>
          <Typography variant="h5">Media</Typography>
        </Grid>
        <Grid item>
          <Tooltip title="Dodajte sliku">
            <Button component="label" fullWidth sx={{ p: 0 }}>
              <input
                required
                hidden
                name="large"
                type="file"
                accept=".png, .jpg, .jpeg, .webp"
                ref={imageRef}
                onInput={() => uploadImage(imageRef)}
                onClick={(event) => { event.target.value = null }}
              />
              {!isCropMode && (
                <AddCircleIcon fontSize="large" sx={{ color: "#fff", p: 0 }} />
              )}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      {uploadedImage && (
        <Box sx={{ borderTop: "1px solid #fff" }}>
          <Box>
            <Crop
              image={uploadedImage}
              setImage={setUploadedImage}
              setImageFile={setMediaFile}
              aspect={5 / 3}
              croppedImage={croppedImage}
              setCroppedImage={setCroppedImage}
              setIsCropMode={setIsCropMode}
              setIsDialogOpened={setIsDialogOpened}
            />
          </Box>
        </Box>
      )}
      {croppedImage && (
        <Box sx={{ textAlign: "right", padding: "10px"}}>
          <Button variant='contained' sx={btnStyle} onClick={addMedia}>Završi</Button>
        </Box>
      )}
      {!isCropMode && !!(formValues.media || []).length && (
        <Box p={1}>
          <Grid container>
            {(formValues.media || []).map((item, key) => (
              <Grid item xs={3} key={key}>
                <Box sx={{ position: "relative", "&:hover .delete-icon": { display: "block" } }}>
                  <img src={process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.src} alt="" style={{ width: "100%", padding: "5px" }}></img>
                  <Box className="delete-icon" onClick={(event) => deleteMedia(event, item.id)} sx={{ position: "absolute", top: "10px", left: "10px", backgroundColor: "#fff", padding: "5px", height: "35px", borderRadius: "50%", display: "none" }}>
                    <DeleteForeverIcon sx={{ fill: '#2f2848', opacity: '0.85', cursor: "pointer" }} fontSize='medium' />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Dialog open={isDialogOpened}>
        <DialogContent>Da li ste sigurni da želite da poništite podešavanja slike?</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpened(false)} sx={{ color: "#2F2848" }}>Odustani</Button>
          <Button onClick={resetData} variant='contained' sx={{ backgroundColor: "#2F2848", "&:hover": { backgroundColor: "#443A69" } }}>Poništi</Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}
export default MediaCard