export const createCabinetsApi = (fetchApi) => {

  function getCabinets() {
    return fetchApi(`/cabinets`, 'GET');
  }

  function getCabinetById(id) {
    return fetchApi(`/cabinet/${id}`, 'GET');
  }

  function addCabinet(data) {
    return fetchApi(`/cabinet`, 'POST', data);
  }

  function updateCabinet(id, data) {
    return fetchApi(`/cabinet/${id}`, 'POST', data);
  }

  return {
    addCabinet,
    getCabinets,
    updateCabinet,
    getCabinetById
  };
};