export const createNewsApi = (fetchApi) => {

  function getAllNews() {
    return fetchApi(`/news`, 'GET');
  }

  function getNewsById(id) {
    return fetchApi(`/news/${id}`, 'GET');
  }

  function addNews(data) {
    return fetchApi(`/news`, 'POST', data);
  }

  function updateNews(id, data) {
    return fetchApi(`/news/${id}`, 'POST', data);
  }

  return {
    addNews,
    getAllNews,
    updateNews,
    getNewsById
  };
};