import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { api } from "../../services"
import CategoryCard from "../../components/Categories/CategoryCard"

const Categories = () => {
  const [newsCategories, setNewsCategories] = useState([]);
  const [galleryCategories, setGalleryCategories] = useState([]);
  const [newsTags, setNewsTags] = useState([]);
  const [isPreLoaded, setIsPreLoaded] = useState(false);

  const fetchNewsCategories = () => {
    api.categories.getNewsCategories().then(res => {
      setNewsCategories(res);
    })
  };

  const fetchGalleryCategories = () => {
    api.categories.getGalleryCategories().then(res => {
      setGalleryCategories(res);
    })
  };

  const fetchNewsTags = () => {
    api.categories.getNewsTags().then(res => {
      setNewsTags(res);
    })
  };

  useEffect(() => {
    setIsPreLoaded(true)
  }, []);

  useEffect(() => {
    if (isPreLoaded)
      fetchNewsCategories();
    fetchGalleryCategories();
    fetchNewsTags();
  }, [isPreLoaded]);

  return (
    <Grid container spacing={4} sx={{ p: 2 }}>
      <Grid item xs={12} md={6} xl={4} justifyContent="space-between">
        <CategoryCard categories={newsTags} getAllCategories={fetchNewsTags} title="Tagovi za novosti" type="tag" />
      </Grid>
      <Grid item xs={12} md={6} xl={4} justifyContent="space-between">
        <CategoryCard categories={newsCategories} getAllCategories={fetchNewsCategories} title="Kategorije za novosti" type="news" />
      </Grid>
      <Grid item xs={12} md={6} xl={4} justifyContent="space-between">
        <CategoryCard categories={galleryCategories} getAllCategories={fetchGalleryCategories} title="Kategorije za galeriju" type="gallery" />
      </Grid>
    </Grid>
  )
}

export default Categories