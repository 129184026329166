import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import CasinoSharpIcon from '@mui/icons-material/CasinoSharp';
import NewspaperSharpIcon from '@mui/icons-material/NewspaperSharp';
import VideogameAssetSharpIcon from '@mui/icons-material/VideogameAssetSharp';
import CollectionsSharpIcon from '@mui/icons-material/CollectionsSharp';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import CategoryIcon from '@mui/icons-material/Category';
import { t } from "i18next";
import Filter7Icon from '@mui/icons-material/Filter7';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Divider from '@mui/material/Divider';
import { Badge } from "@mui/material";
import { NovoContext, NovoContextType } from "../../context/novo";
import { api } from "../../services";

export const DRAWER_WIDTH = 240;

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // Necessary for content to be below app bar.
  ...theme.mixins.toolbar,
}));

const Sidebar = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { state, dispatch } = useContext(NovoContext);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openedMixin = (theme) => ({
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const securedLinks = [
    {
      type: "home",
      icon: <HomeIcon fontSize='large' />
    },
    {
      type: "cabinets",
      icon: <VideogameAssetSharpIcon fontSize='large' />,
    },
    {
      type: "gameMixes",
      icon: <CasinoSharpIcon fontSize='large' />,
    },
    {
      type: "games",
      icon: <Filter7Icon fontSize='large' />,
    },
    {
      type: "news",
      icon: <NewspaperSharpIcon fontSize='large' />,
    },
    {
      type: "gallery",
      icon: <CollectionsSharpIcon fontSize='large' />,
    },
    {
      type: "categories",
      icon: <ChecklistRtlIcon fontSize='large' />,
    },
    {
      type: "platforms",
      icon: <CategoryIcon fontSize='large' />,
    }
  ];

  useEffect(() => {
    if(!state.token) return;
    api.preview.getElementsForPublish().then(res => {
      if (!res) return;
      if(!!(res.cabinets.length || res.game_mixes.length || res.news.length || res.gallery.length)) {
        dispatch({
          type: NovoContextType.SET_BADGE,
          data: true,
        });
      }
    })
  }, [state.token])

  return (
    <div>
      {sessionStorage.getItem("token") && (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <List>
            {securedLinks.map((item, index) => (
              <ListItem key={index} disablePadding sx={{ display: "block" }} onClick={() => navigate(`/${item.type}`)}>
                <ListItemButton sx={{ margin: "2px 1px", backgroundColor: location.pathname === "/" + item.type ? "#B9B9D6" : "", color: location.pathname === "/" + item.type ? "#fff" : "", minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, "&:hover": { backgroundColor: "#B9B9D6" }, "& .Mui-selected": { backgroundColor: "red" } }}>
                  <ListItemIcon title={t(item.type ? item.type : 'home')} sx={{ minWidth: 0, m: 1, justifyContent: "center", color: location.pathname === "/" + item.type ? "#fff" : "#2f2848" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={t(item.type ? item.type : 'home')} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
            <Divider />
            <ListItem disablePadding sx={{ display: "block" }} onClick={() => navigate('/preview')}>
              <ListItemButton sx={{ margin: "2px 1px", backgroundColor: location.pathname === "/preview" ? "#B9B9D6" : "", color: location.pathname === "/preview" ? "#fff" : "", minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, "&:hover": { backgroundColor: "#B9B9D6" }, "& .Mui-selected": { backgroundColor: "red" } }}>
                <ListItemIcon title={t('preview')} sx={{ minWidth: 0, m: 1, justifyContent: "center", color: location.pathname === "/preview" ? "#fff" : "#2f2848", "& .MuiBadge-dot": { backgroundColor: "#B52828" } }}>
                  {state.badge ? (
                    <Badge color="secondary" badgeContent=" " variant="dot">
                      <RemoveRedEyeIcon />
                    </Badge>
                  ) : (
                    <RemoveRedEyeIcon />
                  )}
                </ListItemIcon>
                <ListItemText primary={t('preview')} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      )}
    </div>
  );
};

export default Sidebar;
