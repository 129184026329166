import { Box, Card, FormControl, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material"

const TechnicalCharacteristicsCard = ({ formValues, setFormValues }) => {

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    })
  }

  return (
    <Card elevation={4} sx={{ mt: 4 }}>
      <Box sx={{ p: 2, transition: "0.5s", backgroundColor: "#2F2848", color: "#fff" }}>
        <Typography variant="h5">Tehničke karakteristike</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <FormControl sx={{ mr: 1, width: 120 }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Širina</InputLabel>
          <OutlinedInput
            onChange={handleChange}
            name="width"
            value={(formValues && formValues.width) || ""}
            type="number"
            label="width"
            endAdornment={
              <InputAdornment position="end">mm
              </InputAdornment>
            }
            inputProps={{
              min: 0
            }}
          />
        </FormControl>
        <FormControl sx={{ mr: 1, width: 120 }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Visina</InputLabel>
          <OutlinedInput
            name="height"
            onChange={handleChange}
            value={(formValues && formValues.height) || ""}
            id="outlined-adornment-password"
            type="number"
            label="height"
            endAdornment={
              <InputAdornment position="end">mm
              </InputAdornment>
            }
            inputProps={{
              min: 0
            }}
          />
        </FormControl>
        <FormControl sx={{ width: 120 }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Dubina</InputLabel>
          <OutlinedInput
            onChange={handleChange}
            name="depth"
            value={(formValues && formValues.depth) || ""}
            id="outlined-adornment-password"
            type="number"
            label="depth"
            endAdornment={
              <InputAdornment position="end">mm
              </InputAdornment>
            }
            inputProps={{
              min: 0
            }}
          />
        </FormControl>
      </Box>
    </Card>
  )
}
export default TechnicalCharacteristicsCard