import { Box, Button, Card, Grid, Tooltip, Typography } from "@mui/material"
import { useRef, useState } from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';

const CabinetImageCard = ({errors, setCabinetImageFile, action, formValues}) => {
  const imageRef = useRef();
  const [uploadedCabinetImage, setUploadedCabinetImage] = useState(null);

  const ERROR_COLOR = "#B52828"

  const uploadCabinetImage = (ref) => {
    const image = URL.createObjectURL(ref.current.files[0]);
    setUploadedCabinetImage(image);
    setCabinetImageFile(ref.current.files[0]);
  }

  return (
    <Card elevation={4} sx={{ mt: 4 }}>
      <Box sx={{ p: 2, pr: 0, transition: "0.5s", backgroundColor: errors.cabinetImage ? ERROR_COLOR : "#2F2848", color: "#fff" }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">*Slika automata</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Dodajte sliku">
              <Button component="label" fullWidth sx={{ p: 0 }}>
                <input
                  required
                  hidden
                  name="large"
                  type="file"
                  accept=".png, .jpg, .jpeg, .webp"
                  ref={imageRef}
                  onChange={() => uploadCabinetImage(imageRef)}
                />
                {action === "add" && (
                  <>
                    {!uploadedCabinetImage && <AddCircleIcon fontSize="large" sx={{ color: "#fff", p: 0 }} />}
                  </>
                )}
                {(uploadedCabinetImage || formValues['cabinet_image']) && <EditIcon fontSize="large" sx={{ color: "#fff", p: 0 }} ></EditIcon>}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      {(uploadedCabinetImage || formValues.cabinet_image) && (
        <Box p={2} textAlign="center">
          <img src={uploadedCabinetImage || `https://api.novoinvestment.rs/${formValues.cabinet_image.src}`} alt="" style={{ maxWidth: "100%" }}></img>
        </Box>
      )}
    </Card>
  )
}
export default CabinetImageCard