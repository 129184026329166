import { Box, Card, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material"
import { api } from "../../services";
import { useEffect, useState } from "react";

const CabinetsCard = ({errors, formValues, setFormValues}) => {
  const [cabinets, setCabinets] = useState();
  const [isPreLoaded, setIsPreLoaded] = useState(false);

  const ERROR_COLOR = "#B52828"

  const selectCabinet = (event, cabinet) => {
    const cabinetId = Number(cabinet.id)
    const checked = event.target.checked
    if (checked) {
      const selectedCabinets = formValues.cabinetIds ? [...formValues.cabinetIds, cabinetId] : [cabinetId]
      const cabinets = [...new Set(selectedCabinets)]
      setFormValues({ ...formValues, cabinetIds: cabinets })
    }
    else {
      setFormValues({ ...formValues, cabinetIds: (formValues.cabinetIds || []).filter(id => id !== cabinetId) })
    }
  }

  const fetchCabinets = () => {
    api.cabinets.getCabinets().then(res => {
      setCabinets(res.filter(c => c.status === "active" || c.status === "published"));
    })
  };

  useEffect(() => {
    setIsPreLoaded(true)
  }, []);

  useEffect(() => {
    if (isPreLoaded) {
      fetchCabinets()
    }
  }, [isPreLoaded]);
  
  return (
    <Card elevation={4}>
      <Box sx={{ p: 2, transition: "0.5s", backgroundColor: errors.cabinets ? ERROR_COLOR : "#2F2848", color: "#fff" }}>
        <Typography variant="h5">*Automati:</Typography>
      </Box>
      <FormGroup sx={{ mb: 3, p: 2 }}>
        <Grid container>
          {cabinets && cabinets.map((cabinet, key) => (
            <Grid key={key} item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={((formValues || {}).cabinetIds || []).includes(cabinet.id)}
                    onChange={(event) => selectCabinet(event, cabinet)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={cabinet.name} />
            </Grid>)
          )}
        </Grid>
      </FormGroup>
    </Card>
  )
}
export default CabinetsCard