import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material"
import PreviewCard from "../../components/Preview/PreviewCard"
import { useContext, useEffect, useState } from "react"
import { api } from "../../services";
import { toast } from "react-toastify";
import { NovoContext, NovoContextType } from "../../context/novo";
import { Link } from "react-router-dom";

const PreviewPage = () => {
  const [elements, setElements] = useState(null);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const { dispatch } = useContext(NovoContext);

  const btnStyle = {
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    border: "1px solid #2F2848",
    color: "#2F2848",
    textDecoration: "none",
    padding: "7px 20px",
    borderRadius: "5px",
    fontSize: "14px",
    "&:hover": { backgroundColor: "#443A69" },
    float: "left",
    marginRight: "10px"
  }

  const publishChanges = () => {
    api.preview.publishChanges().then(res => {
      if (!res) return;
      setIsDialogOpened(false);
      dispatch({
        type: NovoContextType.SET_BADGE,
        data: false,
      });
      toast.success('Uspešno ste objavili izmene na sajt', { autoClose: 2000 })
      fetchElements();
    })
  }

  const fetchElements = () => {
    api.preview.getElementsForPublish().then(res => {
      if (!res) return;
      setElements(res)
    })
  }

  useEffect(() => {
    fetchElements();
  }, [])

  return (
    <Box>
      {!!(elements && (elements.cabinets.length || elements.game_mixes.length || elements.news.length || elements.gallery.length)) && (
        <Grid container>
          <Grid item sx={{ mb: 3 }}>
            <Link to="https://dev.novoinvestment.rs/" target="_blank" style={btnStyle}>POGLEDAJ IZMENE</Link>
          </Grid>
          <Grid item sx={{ mb: 3 }}>
            <Button variant='contained' sx={{ backgroundColor: "#2F2848", "&:hover": { backgroundColor: "#443A69" } }} onClick={() => setIsDialogOpened(true)}>Objavi izmene na sajt</Button>
          </Grid>
        </Grid>
      )}
      {(elements && (elements.cabinets.length || elements.game_mixes.length || elements.news.length || elements.gallery.length)) ? (
        <Grid container spacing={4}>
          {!!elements.cabinets.length && (
            <Grid item xs={12} md={6} xl={4} justifyContent="space-between">
              <PreviewCard title="Automati" data={elements.cabinets} />
            </Grid>
          )}
          {!!elements.game_mixes.length && (
            <Grid item xs={12} md={6} xl={4} justifyContent="space-between">
              <PreviewCard title="Miksevi igara" data={elements.game_mixes} />
            </Grid>
          )}
          {!!elements.news.length && (
            <Grid item xs={12} md={6} xl={4} justifyContent="space-between">
              <PreviewCard title="Novosti" data={elements.news} />
            </Grid>
          )}
          {!!elements.gallery.length && (
            <Grid item xs={12} md={6} xl={4} justifyContent="space-between">
              <PreviewCard type="gallery" title="Galerija" data={elements.gallery} />
            </Grid>
          )}
        </Grid>
      ) : (
        <Typography variant="h4" textAlign="center">Nema izmena za objavljivanje na sajt</Typography>
      )}
      <Dialog open={isDialogOpened}>
        <DialogContent>Sve izmene će biti objavljene na sajt. Da li ste sigurni da želite da objavite?</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpened(false)} sx={{ color: "#2F2848" }}>Odustani</Button>
          <Button onClick={publishChanges} variant='contained' sx={{ backgroundColor: "#2F2848", "&:hover": { backgroundColor: "#443A69" } }}>Objavi</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
export default PreviewPage