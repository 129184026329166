import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { toast } from "react-toastify";
import { api } from "../../services";
import { NovoContext, NovoContextType } from "../../context/novo";

const GalleryDialog = ({ data, action, isModalodalOpened, setIsModalOpened, loadGallery }) => {
  const [imageData, setImageData] = useState(data);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const imageRef = useRef();
  const [uploadedImg, setUploadedImg] = useState(null);
  const [isImgValid, setIsImgValid] = useState(true);
  const [categories, setCategories] = useState(null);
  const [isCategoryValid, setIsCategoryValid] = useState(true);
  const { dispatch } = useContext(NovoContext);

  const handleChange = (event) => {
    setSelectedCategory({
      title: event.target.name,
      id: event.target.value
    });
  };

  const uploadImage = (ref) => {
    setUploadedImg(URL.createObjectURL(ref.current.files[0]))
    setImageData(ref.current.files[0]);
    setIsImgValid(true);
  }

  const addImage = () => {
    if (!uploadedImg) {
      setIsImgValid(false);
      return;
    }

    if (!selectedCategory) {
      setIsCategoryValid(false);
      return;
    }
    const formData = new FormData();
    formData.append('image', imageData);
    formData.append('category_id', selectedCategory.id)
    api.gallery.addImages(formData).then(res => {
      if (!res) return;
      loadGallery();
      setIsModalOpened(false);
      setUploadedImg(null)
      setIsImgValid(true);
      setIsCategoryValid(true);
      dispatch({
        type: NovoContextType.SET_BADGE,
        data: true,
      });
      toast.success("Slika je uspešno dodata", { autoClose: 2000 })
    })
  }

  const updateImage = () => {
    const mediaId = data['media_id']
    const categoryId = 'category_id';
    const request = {
      [categoryId]: selectedCategory.id
    }
    api.gallery.updateImage(mediaId, request).then(res => {
      if (!res) return;
      setIsModalOpened(false);
      loadGallery();
      dispatch({
        type: NovoContextType.SET_BADGE,
        data: true,
      });
      toast.success("Slika je uspešno izmenjena", { autoClose: 2000 })
    })
  }

  const handleAction = () => {
    if (action === "add") addImage();
    else updateImage();
  }

  const closeDialog = () => {
    setIsModalOpened(false);
    setUploadedImg(null);
    setIsImgValid(true);
    setIsCategoryValid(true);
  }

  const fetchCategories = () => {
    api.categories.getGalleryCategories().then(res => {
      if (!res) return;
      setCategories(res);
    })
  }

  useEffect(() => {
    if (isModalodalOpened) fetchCategories();
  }, [isModalodalOpened])

  useEffect(() => {
    setSelectedCategory((data && data.categories) ? data.categories[0] : null)
  }, [data])

  return (
    <Dialog open={isModalodalOpened}>
      <DialogContent>
        <Box textAlign="center">
          <img style={{ maxHeight: '500px', maxWidth: '100%' }} src={action === "add" ? uploadedImg : process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + data.original} alt="" />
          {action === "add" && (
            <Button component="label" fullWidth sx={{ border: !isImgValid ? "1px solid red" : "1px solid #ddd", mb: 1, color: "#2F2848" }}>
              <input
                required
                multiple
                hidden
                name="large"
                type="file"
                accept=".png, .jpg, .jpeg, .webp"
                ref={imageRef}
                onChange={() => uploadImage(imageRef)}
              />
              <Grid container sx={{ alignItems: "center", justifyContent: "center" }}>
                <Grid item>Izaberite sliku</Grid>
                <Grid item>
                  <FileUploadIcon sx={{ marginLeft: "10px", marginTop: "7px", color: "#2F2848" }} />
                </Grid>
              </Grid>
            </Button>
          )}
          <Typography fontSize={14}>*Slika mora biti dimenzija 1920x1080</Typography>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} fullWidth>
            <InputLabel id="demo-simple-select-standard-label" >Kategorija</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedCategory ? selectedCategory.id : ""}
              name={selectedCategory ? selectedCategory.title : ""}
              onChange={handleChange}
              label="Kategorija"
              error={!isCategoryValid}
            >
              {categories && categories.map((item, key) => (
                <MenuItem value={item.id} name={item.title} key={key}>{item.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} sx={{ color: "#2F2848" }}>Odustani</Button>
        <Button onClick={handleAction} autoFocus variant='contained' sx={{ backgroundColor: "#2F2848", "&:hover": { backgroundColor: "#443A69" } }}>{action === "add" ? "Dodaj" : "Izmeni"}</Button>
      </DialogActions>
    </Dialog>
  )
}
export default GalleryDialog