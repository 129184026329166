import React, { useContext, useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NovoContext, NovoContextType } from "../../context/novo";
import { Grid, Avatar, TextField, Button } from "@mui/material";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { api } from "../../services";
import { t } from "i18next";

const boxStyle = {
  margin: "auto",
  textAlign: "center"
};

const avatarStyle = { backgroundColor: "#2F2848", margin: "auto" };
const btnStyle = { backgroundColor: "#2F2848", "&:hover": {backgroundColor: "#443A69"}, margin: "8px 0", height: 50 };
const txtFieldStyle = { margin: "10px 0" };

const LoginPage = () => {
  const formRef = useRef(null);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch } = useContext(NovoContext);

  const login = () => {    
    api.users.login( {...loginData, email: "+" + loginData.username }).then((res) => {
      if (!res) return;
      sessionStorage.setItem("token", JSON.stringify(res));
      dispatch({
        type: NovoContextType.SET_TOKEN,
        data: res,
      });
      location.state ? navigate(location.state) : navigate("/home");
    });
  };

  const changeHandler = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (sessionStorage.getItem("token")) navigate("/home");
  }, []);

  return (
    <Grid>
      <Box style={boxStyle} ref={formRef} id="loginForm">
        <h2>Sign in</h2>
        <Grid container>
          <Grid style={boxStyle} item xs={12} sm={8} lg={4}>
            <Avatar style={avatarStyle}>
              <LockOutlinedIcon />
            </Avatar>
            <TextField required id="phoneNumber" name="username" label={t("user_name")} placeholder={t("enter_username")} value={loginData.username} style={txtFieldStyle} fullWidth 
              onChange={changeHandler}
              onKeyPress={(event) => {
                if (event.key === 'Enter')
                login();
              }}
            />
            <TextField required name="password" label={t("password")} placeholder={t("enter_password")} value={loginData.password} fullWidth 
              type={showPassword ? 'text' : 'password'} 
              onChange={changeHandler} 
              onKeyPress={(event) => {
                if (event.key === 'Enter')
                login();
              }}
              InputProps={{
              endAdornment: 
            <InputAdornment position="end">
            <IconButton onClick={ () => setShowPassword((showPassword) => !showPassword) } onMouseDown={ (event) => event.preventDefault() }>
                  {showPassword ? <VisibilityOffIcon sx={{color: "#2F2848"}} /> : <VisibilityIcon sx={{color: "#2F2848"}}/>}
            </IconButton>
            </InputAdornment>
              }}
            />
            <Button type="submit" onClick={login} variant="contained" sx={btnStyle} fullWidth>Sign in</Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default LoginPage;
