import * as React from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from "@mui/material";
import Grid from "@mui/material/Grid";
import GalleryCard from '../../components/Gallery/GalleryCard';
import { useState, useEffect } from 'react';
import GalleryDialog from '../../components/Gallery/GalleryDialog';
import { api } from '../../services';

const GalleryPage = () => {
  const [activeImages, setActiveImages] = useState(null);
  const [deletedImages, setDeletedImages] = useState(null);
  const [activeImagesFormatted, setActiveImagesFormatted] = useState(null);
  const [deletedImagesFormatted, setDeletedImagesFormatted] = useState(null);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [isPreLoaded, setIsPreLoaded] = useState(false);
  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({
    title: "sve",
    id: 0
  });
  const [selectedTab, setSelectedTab] = useState('active');

  const fetchGallery = () => {
    switch (selectedTab) {
      case "active":
        if (activeImages) return;
        fetchActiveGallery();
        break;
      case "deleted":
        if (deletedImages) return;
        fetchDeletedGallery();
        break;

      default:
        break;
    }
  }

  const fetchActiveGallery = () => {
    api.gallery.getActiveImages().then(res => {
      setActiveImages(res);
      setActiveImagesFormatted(res);
    })
  };

  const fetchDeletedGallery = () => {
    api.gallery.getDeletedImages().then(res => {
      setDeletedImages(res);
      setDeletedImagesFormatted(res);
    })
  };

  const handleChange = (event) => {
    if (!categories) return;
    const categoryId = event.target.value;
    const categoryTitle = event.target.name

    setSelectedCategory({
      title: categoryTitle,
      id: categoryId
    });

    if (categoryId === 0) selectedTab === "active" ? setActiveImagesFormatted(activeImages) : setDeletedImagesFormatted(deletedImages);
    else selectedTab === "active" ? setActiveImagesFormatted(activeImages.filter(ai => ai.categories[0] && (ai.categories[0].id === categoryId))) : setDeletedImagesFormatted(deletedImages.filter(ai => ai.categories[0] && (ai.categories[0].id === categoryId)))
  }

  const getCategories = () => {
    api.categories.getGalleryCategories().then(res => {
      setCategories(res);
    })
  }

  const changeTab = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedCategory({
      title: "sve",
      id: 0
    })
    selectedTab === "active" ? setActiveImagesFormatted(activeImages) : setDeletedImagesFormatted(deletedImages);
  }

  useEffect(() => {
    setIsPreLoaded(true)
  }, []);

  useEffect(() => {
    if (isPreLoaded) {
      fetchGallery();
      if (!categories) getCategories();
    }
  }, [isPreLoaded, selectedTab]);

  
  useEffect(() => {
    setActiveImagesFormatted(activeImages);
    setDeletedImagesFormatted(deletedImages)
  }, [activeImages, deletedImages]);

  return (
    <>
      <Box sx={{ background: "#2F2848" }} >
        <Tabs
          value={selectedTab}
          onChange={changeTab}
          sx={{
            ".Mui-selected": {
              color: "#2F2848 !important",
              backgroundColor: "white",
              fontWeight: 600
            }
          }}
          TabIndicatorProps={{ style: { background: '#2F2848' } }}
        >
          <Tab value="active" label="Aktivne slike" sx={{ color: "#fff", "&:hover": { backgroundColor: "#fff", color: "#2F2848 !important" } }} />
          <Tab value="deleted" label="Obrisane slike" sx={{ color: "#fff", "&:hover": { backgroundColor: "#fff", color: "#2F2848 !important" } }} />
        </Tabs>
      </Box>

      <Grid container justifyContent={selectedTab === "active" ? "space-between" : "end"} alignItems="center" mt={2} mb={3}>
        {selectedTab === "active" && (
          <Grid item>
            <Button variant='contained' sx={{backgroundColor: "#2F2848", "&:hover": {backgroundColor: "#443A69"}}} onClick={() => setIsDialogOpened(true)}>Dodaj novu sliku</Button>
          </Grid>
        )}
        <Grid item>
          <FormControl variant="standard" fullWidth sx={{ m: 1, minWidth: 200 }} >
            <InputLabel id="demo-simple-select-standard-label">Kategorija</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedCategory ? selectedCategory.id : ""}
              name={selectedCategory ? selectedCategory.title : ""}
              onChange={handleChange}
              label="Kategorija"
            >
              <MenuItem value={0} name="sve">Sve kategorije</MenuItem>
              {categories && categories.map((item, key) => (
                <MenuItem value={item.id} name={item.title} key={key}>{item.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {selectedTab === "active" && (
        <>
          <Grid container spacing={3}>
            {activeImagesFormatted && activeImagesFormatted.map((item, key) => (
              <Grid xs={12} sm={6} md={4} xl={3} item key={key}>
                <GalleryCard data={item} tab={selectedTab} getActiveImages={fetchActiveGallery} getDeletedImages={fetchDeletedGallery} activeImages={activeImages} setActiveImages={setActiveImages} />
              </Grid>
            ))}
          </Grid>
          {(activeImagesFormatted && !activeImagesFormatted.length) && (
            <Box sx={{ fontSize: "20px", textAlign: "center", marginTop: "50px" }}>Nema slika</Box>
          )}
        </>
      )}
      {selectedTab === "deleted" && (
        <>
          <Grid container spacing={3}>
            {deletedImagesFormatted && deletedImagesFormatted.map((item, key) => (
              <Grid xs={12} sm={6} md={4} lg={3} xl={3} item key={key}>
                <GalleryCard data={item} tab={selectedTab} getActiveImages={fetchActiveGallery} getDeletedImages={fetchDeletedGallery} activeImages={activeImages} setActiveImages={setActiveImages}/>
              </Grid>
            ))}
          </Grid>
          {(deletedImagesFormatted && !deletedImagesFormatted.length) && (
            <Box sx={{ fontSize: "20px", textAlign: "center", marginTop: "50px" }}>Nema slika</Box>
          )}
        </>
      )}
      <GalleryDialog
        action="add"
        data={selectedTab === "active" ? activeImages : deletedImages}
        setData={selectedTab === "active" ? setActiveImagesFormatted : setDeletedImagesFormatted}
        isModalodalOpened={isDialogOpened}
        setIsModalOpened={setIsDialogOpened}
        loadGallery={fetchActiveGallery}
      />
    </>
  )
}
export default GalleryPage