export const createUserApi = (fetchApi) => {

  function login(data) {
    let _data = new URLSearchParams();
    _data.append('email', data.username);
    _data.append('password', data.password);
    return fetchApi(`/login`, 'POST', _data);
  }
  function logout() {
    return fetchApi(`/logout`, 'POST');
  }

  //TODO: Check logout Identity API route

  function getAdmin() {
    return fetchApi(`/administration/me`, 'GET');
  }

  return {
    login,
    logout,
    getAdmin
  };
};
