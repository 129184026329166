import React, { useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
import { Box, Grid } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import CloseIcon from '@mui/icons-material/Close';

const Crop = ({ image, aspect, croppedImage, setCroppedImage, setImageFile, title, setIsDialogOpened }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )
      const response = await fetch(croppedImage);
      const blob = await response.blob();
      const imageFile = new File([blob], 'image.png');
      setImageFile(imageFile);
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (!image) return;
    setCroppedImage(null);
  }, [image])

  return (
    <>
      <Box sx={{ backgroundColor: "#2F2848", color: "#fff", padding: "5px 15px" }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={6}>
            {!croppedImage && (
              <Box dangerouslySetInnerHTML={{ __html: title }}></Box>
            )}
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="end">
              <Grid item sx={{ paddingRight: "10px" }}>
                {!croppedImage && (
                  <Grid container alignItems="center" sx={{ cursor: "pointer", width: "fit-content" }} onClick={showCroppedImage}>
                    <Grid item sx={{ "& svg": { marginTop: "5px" } }}>
                      <ContentCutIcon />
                    </Grid>
                    <Grid item sx={{ paddingLeft: "5px" }}>Iseci sliku</Grid>
                  </Grid>
                )}
                {croppedImage && (
                  <Grid container alignItems="center" sx={{ cursor: "pointer", width: "fit-content" }} onClick={() => setCroppedImage(null)}>
                    <Grid item sx={{ "& svg": { marginTop: "5px" } }}>
                      <ReplayIcon />
                    </Grid>
                    <Grid item sx={{ paddingLeft: "5px" }}>Iseci ponovo</Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Grid container alignItems="center" sx={{ cursor: "pointer", width: "fit-content", marginLeft: "10px" }} onClick={() => { setIsDialogOpened(true) }}>
                  <Grid item sx={{ "& svg": { marginTop: "5px" } }}>
                    <CloseIcon />
                  </Grid>
                  <Grid item sx={{ paddingLeft: "5px" }}>Poništi</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ position: "relative", height: "500px", textAlign: "center", backgroundColor: "#ddd" }}>
        {!croppedImage && (
          <>
            <Box sx={{ height: "500px" }}>
              <Cropper
                image={image}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                zoomSpeed={0.2}
                aspect={aspect}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </Box>
          </>
        )}
        {croppedImage && (
          <Grid container justifyContent="center" alignItems="center" height="100%">
            <Grid item height="100%" container alignItems="center" justifyContent="center">
              <img src={croppedImage} alt='' style={{ maxHeight: "100%", maxWidth: "100%" }}></img>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  )
}
export default Crop