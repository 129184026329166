import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { t } from 'i18next';

const TOKEN_NAME = "token";
const TOKEN_ROUTE = "/connect/token";

const getToken = () => JSON.parse(sessionStorage.getItem(TOKEN_NAME));
const saveToken = token => sessionStorage.setItem(TOKEN_NAME, JSON.stringify(token));
const clearToken = () => sessionStorage.removeItem(TOKEN_NAME);

const getUri = (url) => {
  
  return `${process.env.REACT_APP_NOVOINVESTMENT_API_URL}${url}`;
}

// Refresh Token START
let tokenRequestPending = false;
let tokenRequest;

async function getRefreshedToken() {
  tokenRequestPending = true;
  return fetchApi(`/token/refresh`, 'POST')
    .then(response => {
      if (response.ok) {
        tokenRequestPending = false;
        return response.json();
      }
      clearToken();
      window.location.href = '/login';
    })
    .catch(() => {
      clearToken();
      window.location.href = '/login';
    });
}
// Refresh Token END

async function call(url, type, data, shouldRefreshToken) {
  let token;
  if (shouldRefreshToken) {
    if (!tokenRequestPending) {
      tokenRequest = getRefreshedToken();
    }
    token = await tokenRequest;
    if (!token) return null;
    saveToken(token);
  }
  else token = getToken();

  let params = {
    method: type,
    headers: data instanceof FormData ? 
    {
      "tenantName": "appster" 
    } 
      : 
    {
      'Content-Type': data instanceof URLSearchParams ? 'application/x-www-form-urlencoded' : 'application/json',
      "tenantName": "appster" 
    },
  };
  if (url !== TOKEN_ROUTE) params.headers.authorization = token?.access_token ? `Bearer ${token.access_token}` : null;
  if (data instanceof URLSearchParams) {
    params.body = data.toString();
  } else if (data instanceof FormData) {
    params.body = data;
  } else {
    if (data) params.body = JSON.stringify(data);
  }
  return fetch(getUri(url), params)
    .then(response => {
      if (response.status === 401) {
        return call(url, type, data, true);
      }

      return  {
        ok: response.ok,
        status: response.status,
        data: response.status !== 204 ? response.json() : true
      };
    })
    .then(res => {
      if (res === null) return null;
      const data = res.data;
      if (res.ok) return data;

      if (res.status === 400) {
        data.then(d => {
          if(!d.message) toast.error("An error occured. Please try again.", {autoClose: 3000})
          else {
            for (const key in d.data) {
              toast.error(d.data[key][0], {autoClose: 3000})
            }
        }
          // const _errors = d?.errors;
          // if (_errors === undefined) {
          //   const _error = d?.error;
          //   if (_error === undefined) toast.error(t('An error occured. Please try again.'), {autoClose: 3000})
          //   else toast.error(d.error_description, {autoClose: 3000})
          // } else if (_errors === null) toast.error(t('error_400'), {autoClose: 3000});
          // else Object.keys(_errors).forEach(key => {
          //   toast.error(_errors[key].join(','), {autoClose: 3000});
          // });
        });
      } else if (res.status === 500) {
        data.then(d => {
          toast.error(d.detail, {autoClose: 3000});
        });
      } else if (res.status === 404) {
        toast.error(t('error_404'), {autoClose: 3000});
      } else toast.error(t('An error occured. Please try again.'), {autoClose: 3000});
      return null;
    })
    .catch((err) => {
      toast.error(err.message, { autoClose: 3000 });
    });
}

export const fetchApi = (url, type, data = null, shouldRefreshToken) => {
  return call(url, type, data, shouldRefreshToken);
};