export const createPlatformApi = (fetchApi) => {

  function getPlatforms() {
    return fetchApi(`/platforms`, 'GET');
  }

  function addPlatform(data) {
    return fetchApi(`/platform`, 'POST', data);
  }

  function updatePlatform(id, data) {
    return fetchApi(`/platform/${id}`, 'POST', data);
  }

  return {
    addPlatform,
    getPlatforms,
    updatePlatform
  };
};