import {fetchApi} from "./fetch-api";
import {createUserApi} from "./users";
import {createCabinetsApi} from "./cabinets";
import {createGameMixesApi} from "./gameMixes";
import {createGameApi} from "./games";
import {createNewsApi} from "./news";
import {createPicturesApi} from "./gallery";
import {createCategoriesApi} from "./categories";
import {createPlatformApi} from "./platforms";
import { createPreviewApi } from "./preview";
import { createMediaApi } from "./media";

function createApi(api) {
  return {
    users: createUserApi(api),
    cabinets: createCabinetsApi(api),
    gameMixes: createGameMixesApi(api),
    games: createGameApi(api),
    news: createNewsApi(api),
    gallery: createPicturesApi(api),
    categories: createCategoriesApi(api),
    platforms: createPlatformApi(api),
    preview: createPreviewApi(api),
    media: createMediaApi(api)
  };
}

export const api = createApi(fetchApi);