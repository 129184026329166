import * as React from 'react';
import { Box, Button, Dialog, Slide } from "@mui/material";
import Item from "../../components/Item";
import Grid from "@mui/material/Grid";
import { useEffect, useState, forwardRef } from 'react';
import NewsEditor from './NewsEditor';
import { api } from '../../services';
import { toast } from 'react-toastify';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [news, setNews] = useState([]);
  const [isPreLoaded, setIsPreLoaded] = useState(false);

  const fetchNews = () => {
    api.news.getAllNews().then(res => {
      if(!res) return;
      setNews(res.filter(item => (item.status === "active" || item.status === "published")))
    })
  }

  const deleteNews = (id) => {
    api.news.updateNews(id, {status: "deleted"}).then(res => {
      if(!res) return;
      fetchNews();
      toast.success('Uspešno ste obrisali vest', { autoClose: 2000 })
    })
  }

  useEffect(() => {
    setIsPreLoaded(true);
  }, []);

  useEffect(() => {
    if (isPreLoaded) fetchNews();
  }, [isPreLoaded])

  return (
    <>
      <Grid item sx={{ mb: 3 }}>
        <Button onClick={() => setIsModalOpen(true)} variant='contained' sx={{backgroundColor: "#2F2848", "&:hover": {backgroundColor: "#443A69"}}}>Dodaj novu vest</Button>
      </Grid>
      <Grid container spacing={3}>
        {news.map((item, key) => (
          <Grid xs={12} md={6} lg={4} item key={key}>
            <Item type="news" data={item} loadData={fetchNews} deleteItem={() => deleteNews(item.id)}/>
          </Grid>
        ))}
      </Grid>
      <Dialog open={isModalOpen} fullScreen TransitionComponent={Transition}>
        <Box sx={{ marginTop: { xs: "80px", md: "70px" } }}>
          <NewsEditor action="add" setIsModalOpen={setIsModalOpen} allData={news} setAllData={setNews}></NewsEditor>
        </Box>
      </Dialog>
    </>
  )
}
export default NewsPage