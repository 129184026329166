import React, { useContext, useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { AppBar } from "@mui/material";
import Button from '@mui/material/Button';
import 'react-quill/dist/quill.snow.css';
import validateForm from "../../utils/validation";
import { api } from "../../services";
import { toast } from "react-toastify";
import BasicInfoCard from "../../components/DeatilCards/BasicInfoCard";
import TagsCard from "../../components/DeatilCards/TagsCard";
import CategoriesCard from "../../components/DeatilCards/CategoriesCard";
import CoverImageCard from "../../components/DeatilCards/CoverImageCard";
import MediaCard from "../../components/DeatilCards/MediaCard";
import { NovoContext, NovoContextType } from "../../context/novo";

const NewsEditor = ({ action, newsId, setIsModalOpen, allData, setAllData, loadNews }) => {
  const [formValues, setFormValues] = useState({});
  const formRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageSmallFile, setImageSmallFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [isNew, setIsNew] = useState();
  const [isComingSoon, setIsComingSoon] = useState();
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const { dispatch } = useContext(NovoContext);
  const [isImageCropped, setIsImageCropped] = useState(true);

  const buttonStyle = {
    border: '1px solid white',
    transition: "0.5s",
    backgroundColor: "#2F2848",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#2F2848"
    }
  }

  const validateData = () => {
    let isValid = true;
    let _errors = {}
    if (!validateForm(formRef.current)) {
      _errors = { ..._errors, basicInfo: true }
      isValid = false;
    }
    if (!selectedTagId) {
      _errors = { ..._errors, tags: true }
      isValid = false;
    }
    if (!selectedCategoryId) {
      _errors = { ..._errors, categories: true }
      isValid = false;
    }
    if (!formValues.description) {
      _errors = { ..._errors, description: true }
      isValid = false;
    }
    if ((action === "add" && !imageFile) || (action === "edit" && !formValues.cover_image_small)) {
      _errors = { ..._errors, coverImage: true }
      isValid = false;
    }
    if ((action === "add" && !isImageCropped) || (action === "edit" && (!formValues.cover_image_small || !isImageCropped))) {
      _errors = { ..._errors, coverImage: true }
      isValid = false;
    }
    setErrors(_errors);
    return isValid;
  }

  const handleAction = () => {
    if (action === "add") addNews();
    else updateNews();
  }

  const formatFormData = (formData) => {
    formValues.title && formData.append('title', formValues.title);
    formValues.subtitle && formData.append('subtitle', formValues.subtitle);
    formValues.date && formData.append('date', formValues.date);
    imageFile && formData.append('cover_image', imageFile);
    imageSmallFile && formData.append('cover_image_small', imageSmallFile);
    formValues.description && formData.append('description', formValues.description);
    formData.append('is_new', isNew ? 1 : 0);
    formData.append('is_coming_soon', isComingSoon ? 1 : 0);
    selectedCategoryId && formData.append('category_id', selectedCategoryId);
    selectedTagId && formData.append('tag_id', selectedTagId);
    (formValues.media || []).length && formValues.media.forEach(item => {
      formData.append('media_ids[]', item.id)
    })
  }

  const addNews = () => {
    if (!validateData()) {
      toast.error("Popunite sva obavezna polja", { autoClose: 2000 });
      return;
    }
    const formData = new FormData();
    formatFormData(formData);

    api.news.addNews(formData).then(res => {
      if (!res) return;
      setIsModalOpen(false);
      setAllData([
        res,
        ...allData
      ])
      dispatch({
        type: NovoContextType.SET_BADGE,
        data: true,
      });
      toast.success('Uspešno ste kreirali vest', { autoClose: 2000 })
    })
  }

  const updateNews = () => {
    if (!validateData()) {
      toast.error("Popunite sva obavezna polja", { autoClose: 2000 });
      return;
    }
    const formData = new FormData();
    formatFormData(formData);
    api.news.updateNews(newsId, formData).then(res => {
      if (!res) return;
      setIsModalOpen(false);
      loadNews();
      dispatch({
        type: NovoContextType.SET_BADGE,
        data: true,
      });
      toast.success('Uspešno ste izmenili vest.', { autoClose: 2000 })
    })
  }

  const fetchNews = () => {
    api.news.getNewsById(newsId).then(res => {
      setFormValues(res);
      setSelectedCategoryId(res.categories[0].id);
      setSelectedTagId(res.tags[0].id);
      setIsNew(res['is_new'] ? true : false);
      setIsComingSoon(res['is_coming_soon'] ? true : false);
    })
  }

  useEffect(() => {
    if (action === "edit") fetchNews();
  }, [action])

  return (
    <>
      <AppBar sx={{ position: 'fixed', backgroundColor: "#2F2848" }}>
        <Grid container sx={{ padding: "10px 10px 10px 20px" }} alignItems="center">
          <Grid item sx={{ flex: 1 }}>
            {formValues && <Typography variant="h6">{formValues.title ? formValues.title : ""}</Typography>}
          </Grid>
          <Grid item>
            <Button variant="outline" onClick={() => setIsModalOpen(false)} sx={{ mr: 1, "&:hover": {} }}>Odustani</Button>
            <Button variant="contained" onClick={handleAction} sx={buttonStyle}>Sačuvaj</Button>
          </Grid>
        </Grid>
      </AppBar>
      <Box sx={{ px: 3, py: 1, fontSize: 18 }}>Kartice označene * moraju biti popunjene</Box>
      <Grid container spacing={4} sx={{ p: 2 }} component="form" ref={formRef} noValidate>
        <Grid item container spacing={6} xs={12}>
          <Grid item xs={12} xl={6}>
            <Box>
              <BasicInfoCard
                errors={errors}
                formValues={formValues}
                setFormValues={setFormValues}
                isNew={isNew}
                setIsNew={setIsNew}
                isComingSoon={isComingSoon}
                setIsComingSoon={setIsComingSoon}
                type="news"
                action={action}
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <TagsCard
                errors={errors}
                selectedTagId={selectedTagId}
                setSelectedTagId={setSelectedTagId}
              />
            </Box>
            <Box>
              <CategoriesCard
                errors={errors}
                selectedCategoryId={selectedCategoryId}
                setSelectedCategoryId={setSelectedCategoryId}
              />
            </Box>
          </Grid>
          <Grid item container xs={12} xl={6} alignContent={"start"}>
            <Grid item xs={12}>
              <Box>
                <CoverImageCard
                  errors={errors}
                  imageFile={imageFile}
                  setImageFile={setImageFile}
                  imageSmallFile={imageSmallFile}
                  setImageSmallFile={setImageSmallFile}
                  action={action}
                  formValues={formValues}
                  setIsImageCropped={setIsImageCropped}
                />
              </Box>
              <Box sx={{ mt: 4 }}>
                <MediaCard
                  action={action}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default NewsEditor;