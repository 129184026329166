export const createPicturesApi = (fetchApi) => {

  function getActiveImages() {
    return fetchApi(`/gallery/active`, 'GET');
  }

  function getDeletedImages() {
    return fetchApi(`/gallery/deleted`, 'GET');
  }

  function addImages(data) {
    return fetchApi(`/gallery/upload/image`, 'POST', data);
  }

  function updateImage(id, data) {
    return fetchApi(`/gallery/image/${id}`, 'PUT', data);
  }

  return {
    getActiveImages,
    addImages,
    getDeletedImages,
    updateImage
  };
};