import React, { useContext, useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { AppBar } from "@mui/material";
import Button from '@mui/material/Button';
import 'react-quill/dist/quill.snow.css';
import validateForm, { validateVideo } from "../../utils/validation";
import { toast } from "react-toastify";
import { api } from "../../services";
import BasicInfoCard from "../../components/DeatilCards/BasicInfoCard";
import InformationsCard from "../../components/DeatilCards/InformationsCard";
import TechnicalCharacteristicsCard from "../../components/DeatilCards/TechnicalCharacteristicsCard";
import GameMixCard from "../../components/DeatilCards/GameMixCard";
import CoverImageCard from "../../components/DeatilCards/CoverImageCard";
import CabinetImageCard from "../../components/DeatilCards/CabinetImageCard";
import MediaCard from "../../components/DeatilCards/MediaCard";
import VideoCard from "../../components/DeatilCards/VideoCard";
import { NovoContext, NovoContextType } from "../../context/novo";

const CabinetsEditor = ({ action, cabinetId, allData, setAllData, setIsModalOpen, loadCabinets }) => {
  const formRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageSmallFile, setImageSmallFile] = useState(null);
  const [facts, setFacts] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const [cabinetImageFile, setCabinetImageFile] = useState(null);
  const [isNew, setIsNew] = useState();
  const [isComingSoon, setIsComingSoon] = useState();
  const [videoLinks, setVideoLinks] = useState([]);
  const { dispatch } = useContext(NovoContext);
  const [isImageCropped, setIsImageCropped] = useState(true);

  const buttonStyle = {
    border: '1px solid white',
    transition: "0.5s",
    backgroundColor: "#2F2848",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#2F2848"
    }
  }

  const handleAction = () => {
    if (action === "add") addCabinet();
    else updateCabinet();
  }

  const validateData = () => {
    let isValid = true;
    let _errors = {}

    validateForm(formRef.current);

    if (!formValues.name) {
      _errors = { ..._errors, basicInfo: true }
      isValid = false;
    }
    if (!facts || !facts.length || facts.includes('')) {
      _errors = { ..._errors, information: true }
      isValid = false;
    }
    if (!formValues.gameMixIds || !formValues.gameMixIds.length) {
      _errors = { ..._errors, gameMixes: true }
      isValid = false;
    }
    if (!formValues.description || formValues.description === "<p><br></p>") {
      _errors = { ..._errors, description: true, basicInfo: true }
      isValid = false;
    }
    if ((action === "add" && !isImageCropped) || (action === "edit" && (!formValues.cover_image_small || !isImageCropped))) {
      _errors = { ..._errors, coverImage: true }
      isValid = false;
    }
    if ((action === "add" && !cabinetImageFile) || (action === "edit" && !formValues.cabinet_image)) {
      _errors = { ..._errors, cabinetImage: true }
      isValid = false;
    }
    if (videoLinks.includes('')) {
      _errors = { ..._errors, video: true }
      isValid = false;
    }

    setErrors(_errors);
    return isValid;
  }

  const formatFormData = (formData) => {
    formValues.name && formData.append('name', formValues.name);
    formData.append('description', formValues.description);
    imageFile && formData.append('cover_image', imageFile);
    imageSmallFile && formData.append('cover_image_small', imageSmallFile);
    cabinetImageFile && formData.append('cabinet_image', cabinetImageFile);
    formData.append('is_new', isNew ? 1 : 0);
    formData.append('is_coming_soon', isComingSoon ? 1 : 0);
    formValues.width && formData.append('width', formValues.width);
    formValues.height && formData.append('height', formValues.height);
    formValues.depth && formData.append('depth', formValues.depth);
    if (formValues.gameMixIds) for (var i = 0; i < formValues.gameMixIds.length; i++) {
      formData.append('game_mix_ids[]', formValues.gameMixIds[i]);
    }
    facts.forEach(item => formData.append('info[]', item));
    videoLinks && (videoLinks.length ? videoLinks.forEach(item => formData.append('video_links[]', item)) : formData.append('video_links[]', []));
    // formValues.media.length ? formValues.media.forEach(item => formData.append('media_ids[]', item.id)) : formData.append('media_ids[]', [])
    (formValues.media || []).length && formValues.media.forEach(item => {
      formData.append('media_ids[]', item.id)
    })
  }

  const addCabinet = () => {
    if (!validateData()) {
      toast.error("Popunite sva obavezna polja", { autoClose: 2000 });
      return;
    }
    
    if(!validateVideo(formRef.current)) {
      toast.error("Video link mora biti u formatu https://www.novomatic.com/xxx.mp4", { autoClose: 2000 });
      return;
    }
    const formData = new FormData();
    formatFormData(formData);
    api.cabinets.addCabinet(formData).then(res => {
      if (!res) return;
      setIsModalOpen(false);
      setAllData([
        res,
        ...allData
      ])
      dispatch({
        type: NovoContextType.SET_BADGE,
        data: true,
      });
      toast.success('Uspešno ste kreirali automat', { autoClose: 2000 })
    })
  }

  const updateCabinet = () => {
    if (!validateData()) {
      toast.error("Popunite sva obavezna polja", { autoClose: 2000 });
      return;
    }
    if(!validateVideo(formRef.current)) {
      toast.error("Video link mora biti u formatu https://www.novomatic.com/xxx.mp4", { autoClose: 2000 });
      return;
    }
    
    const formData = new FormData();
    formatFormData(formData);
    
    api.cabinets.updateCabinet(cabinetId, formData).then(res => {
      if (!res) return;
      setIsModalOpen(false);
      loadCabinets();
      dispatch({
        type: NovoContextType.SET_BADGE,
        data: true,
      });
      toast.success('Uspešno ste izmenili automat.', { autoClose: 2000 })
    })
  }

  const fetchCabinet = () => {
    api.cabinets.getCabinetById(cabinetId).then(res => {
      setFormValues({ ...res, gameMixIds: res.gameMixes.map(c => c.id) });
      setIsNew(res['is_new'] ? true : false);
      setIsComingSoon(res['is_coming_soon'] ? true : false);
      setFacts(res.info)
      setVideoLinks(res.video_links || [])
    })
  }

  useEffect(() => {
    if (action === "edit") fetchCabinet();
  }, [action])



  return (
    <>
      <AppBar sx={{ position: 'fixed', backgroundColor: "#2F2848" }}>
        <Grid container sx={{ padding: "10px 10px 10px 20px" }} alignItems="center">
          <Grid item sx={{ flex: 1 }}>
            {formValues && <Typography variant="h6">{formValues.name ? formValues.name : ""}</Typography>}
          </Grid>
          <Grid item>
            <Button edge="end" variant="outline" onClick={() => setIsModalOpen(false)} sx={{ mr: 1, "&:hover": {} }}>Odustani</Button>
            <Button edge="end" variant="contained" onClick={handleAction} sx={buttonStyle}>Sačuvaj</Button>
          </Grid>
        </Grid>
      </AppBar>
      <Box sx={{ px: 3, py: 1, fontSize: 18 }}>Kartice označene * moraju biti popunjene</Box>
      <Grid container spacing={4} sx={{ p: 2 }} component="form" ref={formRef} noValidate>
        <Grid item container spacing={6} xs={12}>
          <Grid item xs={12} xl={6}>
            <BasicInfoCard
              errors={errors}
              formValues={formValues}
              setFormValues={setFormValues}
              isNew={isNew}
              setIsNew={setIsNew}
              isComingSoon={isComingSoon}
              setIsComingSoon={setIsComingSoon}
            />
            <Box sx={{ mt: 4 }}>
              <InformationsCard
                errors={errors}
                facts={facts}
                setFacts={setFacts}
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <TechnicalCharacteristicsCard
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <GameMixCard
                errors={errors}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </Box>
          </Grid>
          <Grid item container xs={12} xl={6} alignContent={"start"}>
            <Grid item xs={12}>
              <Box>
                <CoverImageCard
                  errors={errors}
                  imageFile={imageFile}
                  setImageFile={setImageFile}
                  imageSmallFile={imageSmallFile}
                  setImageSmallFile={setImageSmallFile}
                  action={action}
                  formValues={formValues}
                  setIsImageCropped={setIsImageCropped}
                />
              </Box>
              <Box sx={{ mt: 4 }}>
                <CabinetImageCard
                  errors={errors}
                  cabinetImageFile={cabinetImageFile}
                  setCabinetImageFile={setCabinetImageFile}
                  action={action}
                  formValues={formValues}
                />
              </Box>
              <Box sx={{ mt: 4 }}>
                <VideoCard
                  errors={errors}
                  videoLinks={videoLinks}
                  setVideoLinks={setVideoLinks}
                />
              </Box>
              <Box sx={{ mt: 4 }}>
                <MediaCard
                  action={action}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CabinetsEditor;