import { Box, Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from "@mui/material"
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useEffect, useRef, useState } from "react";
import validateForm from "../../utils/validation";
import { toast } from "react-toastify";
import { api } from "../../services";

const GameDialog = ({ action, data, isDialogOpened, setIsDialogOpened, loadGames }) => {
  const imageRef = useRef();
  const [uploadedImg, setUploadedImg] = useState(null);
  const [gameData, setGameData] = useState({});
  const ref = useRef();
  const [isImgValid, setIsImgValid] = useState(true);
  const [updateData, setUpdateData] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setGameData({
      ...gameData,
      [name]: value
    })

    setUpdateData({
      ...updateData,
      [name]: value
    })
  }

  const uploadImage = (ref) => {
    setUploadedImg(URL.createObjectURL(ref.current.files[0]))

    setGameData({
      ...gameData,
      image: ref.current.files[0]
    })

    setUpdateData({
      ...updateData,
      image: ref.current.files[0]
    })
    setIsImgValid(true);
  }

  const handleAction = () => {
    if (action === "add") addGame();
    else updateGame();
  }

  const addGame = () => {
    if (!validateForm(ref.current)) return;
    if (!uploadedImg) {
      setIsImgValid(false);
      return;
    }

    const formData = new FormData();
    formData.append('name', gameData.name);
    formData.append('image', gameData.image);
    formData.append('href', gameData.href);

    api.games.addGame(formData).then(res => {
      if(!res) return;
      loadGames();
      setUploadedImg(null);
      setIsDialogOpened(false);
      resetData();
      document.querySelector('#searchField').value = ''
      toast.success("Igra je uspešno dodata", { autoClose: 2000 });
    });
  }

  const updateGame = () => {
    if (!validateForm(ref.current)) return;

    const formData = new FormData();
    updateData.name && formData.append('name', updateData.name);
    updateData.image && formData.append('image', updateData.image);
    updateData.href && formData.append('href', updateData.href);

    api.games.updateGame(gameData.id, formData).then(res => {
      if (!res) return;
      loadGames();
      setIsDialogOpened(false);
      toast.success("Igra je uspešno ažurirana", { autoClose: 2000 });
    })
  }

  const resetData = () => {
    if (action === "edit") return;
    setGameData({});
    setUploadedImg(null);
  }

  useEffect(() => {
    if (!isDialogOpened) return;
    setGameData(data);
  }, [isDialogOpened])

  return (
    <Dialog open={isDialogOpened}>
      <DialogContent>
        <Box ref={ref} component="form" noValidate autoComplete="off">
          <TextField required id="name" name="name" label="Naziv" value={(gameData && gameData.name) ? gameData.name : ""} onChange={handleChange} fullWidth sx={{ mt: 2 }} />
          <TextField required id="url" name="href" label="Url" value={(gameData && gameData.href) ? gameData.href : ""} placeholder="Url igrice sa globalnog sajta" onChange={handleChange} fullWidth sx={{ mt: 2 }} />
        </Box>
        <Grid container sx={{ mt: 2 }}>
          <Grid item>
            {(uploadedImg || (gameData || {}).media) && (
              <Box sx={{ width: "50px" }}>
                <img src={uploadedImg ? uploadedImg : `https://api.novoinvestment.rs/${gameData.media.src}`} alt="" style={{ width: "100%" }}></img>
              </Box>
            )}
          </Grid>
          <Grid item xs sx={{ pl: (uploadedImg || (gameData || {}).media) ? 1 : "" }}>
            <Button component="label" sx={{ border: !isImgValid ? "1px solid red" : "1px solid #ddd", color: "#2F2848" }} fullWidth>
              <input
                required
                hidden
                name="large"
                type="file"
                accept=".png, .jpg, .jpeg, .webp"
                ref={imageRef}
                onChange={() => uploadImage(imageRef)}
              />
              <Grid container sx={{ alignItems: "center", justifyContent: "center" }}>
                <Grid item>{uploadedImg ? "Izmenite sliku" : "Izaberite sliku"}</Grid>
                <Grid item>
                  <FileUploadIcon sx={{ marginLeft: "10px", marginTop: "7px" }} />
                </Grid>
              </Grid>
            </Button>
            <Typography fontSize={12} mt={1}>*Slika mora biti dimenzija 50x50</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={() => { setIsDialogOpened(false); resetData(); }} sx={{ color: "#2F2848" }} >Odustani</Button>
        <Button onClick={handleAction} autoFocus variant='contained' sx={{ backgroundColor: "#2F2848", "&:hover": { backgroundColor: "#443A69" } }}>{action === "add" ? "Dodaj" : "Izmeni"}</Button>
      </DialogActions>
    </Dialog>
  )
}
export default GameDialog