import { Box, Card, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, InputBase, Typography } from "@mui/material"
import { GridSearchIcon } from "@mui/x-data-grid"
import { useEffect, useState } from "react";
import { api } from "../../services";

const GamesCard = ({ errors, formValues, setFormValues }) => {
  const [formatedGames, setFormatedGames] = useState();
  const [games, setGames] = useState();
  const [isPreLoaded, setIsPreLoaded] = useState(false);

  const ERROR_COLOR = "#B52828"

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setFormatedGames(games.filter(g => g.name.toLowerCase().includes(value)))
  }

  const selectGame = (event, game) => {
    const gameId = Number(game.id)
    const checked = event.target.checked
    if (checked) {
      const selectedGames = formValues.gameIds ? [...formValues.gameIds, gameId] : [gameId]
      const games = [...new Set(selectedGames)]
      setFormValues({ ...formValues, gameIds: games })
    }
    else {
      setFormValues({ ...formValues, gameIds: (formValues.gameIds || []).filter(id => id !== gameId) })
    }
  }

  const fetchGames = () => {
    api.games.getGames().then(res => {
      setGames(res);
    })
  };

  useEffect(() => {
    setIsPreLoaded(true)
  }, []);

  useEffect(() => {
    setFormatedGames(games)
  }, [games]);

  useEffect(() => {
    if (isPreLoaded) {
      fetchGames();
    }
  }, [isPreLoaded]);

  return (
    <Card elevation={4}>
      <Box sx={{ p: 2, transition: "0.5s", backgroundColor: errors.games ? ERROR_COLOR : "#2F2848", color: "#fff" }}>
        <Typography variant="h5">*Igre:</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <InputBase
          id="searchField"
          sx={{ ml: 1, flex: 1, borderBottom: "1px solid #757575" }}
          placeholder="Pretraži igru"
          onChange={handleSearch}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" >
          <GridSearchIcon />
        </IconButton>
      </Box>
      <FormGroup sx={{ mb: 3, p: 2, maxHeight: "350px", overflowY: "auto" }}>
        <Grid container>
          {formatedGames && formatedGames.map((item, key) => (
            <Grid key={key} item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={((formValues || {}).gameIds || []).includes(item.id)}
                    onChange={(event) => selectGame(event, item)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />}
                label={item.name} />
            </Grid>)
          )}
        </Grid>
        {!(formatedGames || []).length && (
            <Box sx={{ fontSize: "20px", textAlign: "center" }}>Igra nije pronadjena</Box>
          )}
      </FormGroup>
    </Card>
  )
}
export default GamesCard