export const createCategoriesApi = (fetchApi) => {

  function getNewsCategories() {
    return fetchApi(`/categories/news`, 'GET');
  }

  function getGalleryCategories() {
    return fetchApi(`/categories/gallery`, 'GET');
  }

  function addCategories(data) {
    return fetchApi(`/category`, 'POST', data);
  }

  function updateCategory(id, data) {
    return fetchApi(`/category/${id}`, 'PUT', data);
  }

  function getNewsTags() {
    return fetchApi(`/tags`, 'GET');
  }

  function addTag(data) {
    return fetchApi(`/tag`, 'POST', data);
  }

  function updateTag(id, data) {
    return fetchApi(`/tag/${id}`, 'PUT', data);
  }

  return {
    getNewsCategories,
    addCategories,
    getGalleryCategories,
    updateCategory,
    getNewsTags,
    addTag,
    updateTag
  };
};