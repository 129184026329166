import { Box, Button, Card, Dialog, DialogActions, DialogContent, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import GalleryDialog from "./GalleryDialog";
import { api } from "../../services";
import ReplyIcon from '@mui/icons-material/Reply';
import { NovoContext, NovoContextType } from "../../context/novo";

const GalleryCard = ({ data, getActiveImages, getDeletedImages, tab, activeImages, setActiveImages }) => {
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [isRestoreModalOpened, setIsRestoreModalOpened] = useState(false);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [isUpdated, setIsupdated] = useState(false);
  const { dispatch } = useContext(NovoContext);

  const deleteImage = () => {
    const mediaId = data['media_id']
    const request = {
      status: "deleted"
    }
    api.gallery.updateImage(mediaId, request).then(res => {
      setActiveImages([...activeImages.filter(image => image.media_id !== res.media_id)]);
      getActiveImages();
      getDeletedImages();
      setIsDeleteModalOpened(false);
      toast.success("Slika je uspešno obrisana", { autoClose: 2000 });
    })
  }

  const restoreImage = () => {
    const mediaId = data['media_id']
    const request = {
      status: "active"
    }
    api.gallery.updateImage(mediaId, request).then(res => {
      getActiveImages();
      getDeletedImages();
      setIsRestoreModalOpened(false);
      dispatch({
        type: NovoContextType.SET_BADGE,
        data: true,
      });
      toast.success("Slika je uspešno aktivirana", { autoClose: 2000 });
    })
  }

  useEffect(() => {
    if (isUpdated) {
      getActiveImages();
      getDeletedImages();
    }
  }, [isUpdated])

  return (
    <>
      <Card elevation={4} sx={{ height: '100%', padding: "10px" }}>
        <img style={{ maxHeight: '100%', maxWidth: '100%' }} src={process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + data.original} alt="" />
        <Box mb={2}>
          <Typography>Kategorija: <strong>{data.categories.length ? data.categories[0].title : ""}</strong></Typography>
        </Box>
        <Grid container sx={{ borderTop: "1px solid #ddd", pt: 1 }} justifyContent="space-between">
          <Grid item>
            {tab === "active" ? (
              <Tooltip title="Izmenite sliku">
                <IconButton component="label" onClick={() => setIsEditModalOpened(true)}>
                  <EditIcon sx={{ color: "#2F2848" }} fontSize='medium' />
                </IconButton>
              </Tooltip>
            ) :
              <Tooltip title="Vratite sliku u aktivan status">
                <IconButton component="label" onClick={() => setIsRestoreModalOpened(true)}>
                  <ReplyIcon sx={{ color: "#2F2848" }} fontSize='medium' />
                </IconButton>
              </Tooltip>
            }
          </Grid>
          {tab === "active" && (
            <Grid item>
              <Tooltip title="Obrišite sliku">
                <IconButton onClick={() => setIsDeleteModalOpened(true)}>
                  <DeleteForeverIcon sx={{ color: "#2F2848" }} fontSize='medium' />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Card>
      <Dialog open={isDeleteModalOpened}>
        <DialogContent><p><strong>*Ova slika će biti prebačena u sekciju obrisanih slika.</strong></p>Da li ste sigurni da želite da obrišete sliku?</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteModalOpened(false)} sx={{color: "#2F2848"}}>Odustani</Button>
          <Button onClick={deleteImage} autoFocus variant='contained' sx={{backgroundColor: "#2F2848", "&:hover": {backgroundColor: "#443A69"}}}>Obriši</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isRestoreModalOpened}>
        <DialogContent><p><strong>*Ova slika će biti prebačena u sekciju aktivnih slika.</strong></p>Da li ste sigurni da želite da ponovo aktivirate sliku?</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRestoreModalOpened(false)} sx={{color: "#2F2848"}}>Odustani</Button>
          <Button onClick={restoreImage} autoFocus variant='contained' sx={{backgroundColor: "#2F2848", "&:hover": {backgroundColor: "#443A69"}}}>Aktiviraj</Button>
        </DialogActions>
      </Dialog>
      <GalleryDialog
        action="edit"
        data={data}
        isModalodalOpened={isEditModalOpened}
        setIsModalOpened={setIsEditModalOpened}
        setIsupdated={setIsupdated}
        loadGallery={getActiveImages}
      />
    </>
  )
}
export default GalleryCard