import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Card from '@mui/material/Card';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, DialogActions, DialogContent, Grid, Tooltip } from '@mui/material';
import CabinetsEditor from '../pages/cabinets/CabinetEditor';
import GameMixesEditor from '../pages/gameMixes/gameMixesEditor';
import NewsEditor from '../pages/news/NewsEditor';
import { useState, forwardRef } from 'react';
import { formatDate } from '../utils/validation';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CardItem = ({ data, type, loadData, deleteItem }) => {
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

  const confirmDelete = () => {
    deleteItem();
    setIsDeleteModalOpened(false);
  }

  return (
    <>
      <Card elevation={4} sx={{ height: '100%', p: 1, position: 'relative' }}>
        {!!data.is_coming_soon && <Box component='img' src='/images/coming-soon.webp' alt='' sx={{ position: 'absolute', top: 0, right: 0, transform: 'rotate(45deg)' }}></Box>}
        {!!data.is_new && <Box component='img' src='/images/new.svg' alt='' sx={{ position: 'absolute', top: 0, right: 0, transform: 'rotate(90deg)' }}></Box>}
        <Grid sx={{ height: '100%' }} container direction='column'>
          <Grid item xs container justifyContent="space-between" direction='column' sx={{ padding: "10px" }}>
            <Grid item xs container>
            <Grid item xs={12}>
                <Box sx={{textAlign: "center", height: type === "cabinets" ? "500px" : "auto"}}>
                  <img style={{ maxHeight: "100%", maxWidth: "100%"}} src={process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + data[type === "cabinets" ? 'cabinet_image' : 'cover_image_small'].src} alt="" />
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ paddingRight: "10px" }}>
                <Typography component="div" variant="h5" pb={1}>
                  {data.title || data.name}
                </Typography>
                <Box>
                  {type !== "news" && <Typography variant="subtitle1" color="text.secondary" component="div" dangerouslySetInnerHTML={{ __html: data.description }}></Typography>}
                </Box>
              </Grid>
              
            </Grid>
            <Grid item xs="auto" container justifyContent="end">
              {type === "news" && (
                <Box style={{ textAlign: "end" }}><strong>{formatDate(data.date)}</strong></Box>
              )}
            </Grid>
          </Grid>
          <Grid item xs="auto" container sx={{ borderTop: "1px solid #ddd", py: 1 }} justifyContent="space-between">
            <Grid item>
              <Tooltip title={`Izmenite ${type === "news" ? "vest" : type === "cabinets" ? "automat" : "miks igara"}`}>
                <IconButton component="label" onClick={() => setIsEditModalOpened(true)}>
                  <EditIcon sx={{ color: "#2F2848" }} fontSize='medium' />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={`Obrišite ${type === "news" ? "vest" : type === "cabinets" ? "automat" : "miks igara"}`}>
                <IconButton onClick={() => setIsDeleteModalOpened(true)}>
                  <DeleteForeverIcon sx={{ color: "#2F2848" }} fontSize='medium' />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Dialog open={isEditModalOpened} fullScreen TransitionComponent={Transition}>
        <Box sx={{ marginTop: { xs: "80px", md: "70px" } }}>
          {type === "cabinets" && (<CabinetsEditor action="edit" cabinetId={data.id} loadCabinets={loadData} setIsModalOpen={setIsEditModalOpened}></CabinetsEditor>)}
          {type === "news" && (<NewsEditor newsId={data.id} loadNews={loadData} action="edit" setIsModalOpen={setIsEditModalOpened}></NewsEditor>)}
          {type === "game-mix" && (<GameMixesEditor gameMixId={data.id} loadGameMixes={loadData} action="edit" setIsModalOpen={setIsEditModalOpened} ></GameMixesEditor>)}
        </Box>
      </Dialog>
      <Dialog
        open={isDeleteModalOpened}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>Da li ste sigurni da želite da obrišete {type === "cabinets" ? "automat" : type === "news" ? "vest" : "miks igara"} <strong>{data.title || data.name}</strong>?</DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={() => setIsDeleteModalOpened(false)} sx={{ color: "#2F2848" }}>Odustani</Button>
          <Button onClick={confirmDelete} autoFocus variant='contained' sx={{ backgroundColor: "#2F2848", "&:hover": { backgroundColor: "#443A69" } }}>Izbriši</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default CardItem