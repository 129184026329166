import { Box, Button, Card, Dialog, DialogActions, DialogContent, FormGroup, Grid, IconButton, TextField, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'
import { useRef, useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { api } from "../../services";
import { toast } from "react-toastify";
import validateForm from "../../utils/validation";

const CategoryCard = ({ categories, getAllCategories, title, type }) => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryTitle, setCategoryTitle] = useState("");
  const formRef = useRef();

  const handleChange = (event) => {
    const value = event.target.value;

    if (selectedCategory) {
      setSelectedCategory({
        ...selectedCategory,
        title: value
      })
    }
    else {
      setCategoryTitle(value);
    }
  }

  const handleAction = () => {
    if (!selectedCategory) addCategory();
    else editCategoryName();
  }

  const addCategory = () => {
    if (!validateForm(formRef.current)) return;
    if (type === "tag") {
      api.categories.addTag({ title: categoryTitle }).then(res => {
        if (!res) return;
        getAllCategories();
        setIsDialogOpened(false);
        setCategoryTitle("")
        toast.success("Uspešno ste dodali tag", { autoClose: 2000 });
      })
    }
    else {
      const request = {
        title: categoryTitle,
        type
      }
      api.categories.addCategories(request).then(res => {
        if (!res) return;
        getAllCategories();
        setIsDialogOpened(false);
        setCategoryTitle("")
        toast.success("Uspešno ste dodali kategoriju", { autoClose: 2000 });
      })
    }
  }

  const handleEdit = (id) => {
    setIsDialogOpened(true)
    setSelectedCategory(categories.find(c => c.id === id));
  }

  const editCategoryName = () => {
    if (!validateForm(formRef.current)) return;
    if (type === "tag") {
      const request = {
        title: selectedCategory.title
      }
      api.categories.updateTag(selectedCategory.id, request).then(res => {
        if (!res) return;
        setIsDialogOpened(false);
        getAllCategories();
        toast.success("Uspešno ste ažurirali kategoriju", { autoClose: 2000 });
      })

    }
    else {
      const request = {
        title: selectedCategory.title,
        type
      }
      api.categories.updateCategory(selectedCategory.id, request).then(res => {
        if (!res) return;
        setIsDialogOpened(false);
        getAllCategories();
        toast.success("Uspešno ste ažurirali kategoriju", { autoClose: 2000 });
      })
    }
  }

  const cancel = () => {
    setIsDialogOpened(false);
    setCategoryTitle("");
  }

  return (
    <>
      <Card elevation={4}>
        <Box sx={{ px: 2, py: 1, backgroundColor: "#2F2848", color: "#fff" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => { setIsDialogOpened(true); setSelectedCategory(null); }}>
                <AddCircleIcon fontSize="large" sx={{ color: "#fff" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}>
          {categories && categories.map((category, key) => (
            <Grid key={key} container justifyContent="space-between" alignItems="center" sx={{ borderTop: key > 0 ? "1px solid #ddd" : "" }}>
              <Grid item>
                <Typography variant="h7" sx={{ mb: 3 }}>{category.title}</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => handleEdit(category.id)}>
                  <EditIcon sx={{ color: "#2F2848" }} />
                </IconButton>
              </Grid>
            </Grid>
          )
          )}
        </Box>
      </Card>
      <Dialog open={isDialogOpened} ref={formRef}>
        <DialogContent>
          <Box component="form" noValidate autoComplete="off">
            <TextField required name="title" label="Naziv" value={selectedCategory ? selectedCategory.title : categoryTitle} onChange={handleChange} fullWidth sx={{ mt: 2 }} />
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={cancel} sx={{ color: "#2F2848" }}>Odustani</Button>
          <Button onClick={handleAction} autoFocus variant='contained' sx={{ backgroundColor: "#2F2848", "&:hover": { backgroundColor: "#443A69" } }}>{selectedCategory ? "Izmeni" : "Dodaj"}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default CategoryCard