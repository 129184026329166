export const createGameMixesApi = (fetchApi) => {

  function getGameMix(id) {
    return fetchApi(`/game-mix/${id}`, 'GET');
  }

  function getGameMixes() {
    return fetchApi(`/game-mixes`, 'GET');
  }

  function addGameMix(data) {
    return fetchApi(`/game-mix`, 'POST', data);
  }

  function updateGameMix(id, data) {
    return fetchApi(`/game-mix/${id}`, 'POST', data);
  }
  
  return {
    getGameMix,
    getGameMixes,
    addGameMix,
    updateGameMix
  };
};