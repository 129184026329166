import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import Preview from "../../images/preview.png"
import CasinoSharpIcon from '@mui/icons-material/CasinoSharp';
import NewspaperSharpIcon from '@mui/icons-material/NewspaperSharp';
import VideogameAssetSharpIcon from '@mui/icons-material/VideogameAssetSharp';
import CollectionsSharpIcon from '@mui/icons-material/CollectionsSharp';
import Filter7Icon from '@mui/icons-material/Filter7';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import CategoryIcon from '@mui/icons-material/Category';

const HomePage = () => {
  const boxStyle = {
    width: "auto",
    display: 'flex',
    flexWrap: 'wrap',
    '& > :not(style)': {
      m: 2,
      ml: 0,
      width: 200,
      height: 200,
      p: 2,
      pt: 3
    }
  }
  return (
    <>
      {sessionStorage.getItem("token") && (
        <Box sx={{ paddingBottom: "20px" }}>
          <Typography variant="h3" noWrap component="div" sx={{ flexGrow: 1, pb: 4 }}>Dobrodošli</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={3}>
              <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1, pb: 2 }}>Web sajt</Typography>
              <Box sx={boxStyle} >
                <Paper target="_blank" href="https://novoinvestment.rs/" component="a" elevation={3} sx={{ textAlign: "center", textDecoration: 'none', paddingTop: "20px" }}>
                  <Box >
                    <img width="70%" src={Preview} alt=""></img>
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: 400, px: 1, color: "#2f2848", opacity: 0.8, }}>Pogledajte vaš sajt</Typography>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={9}>
              <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1, pb: 2 }}>Sledeći koraci</Typography>

              <Box sx={boxStyle} >
                <Paper component="a" href="/cabinets" elevation={3} sx={{ textDecoration: "none", textAlign: "center" }}>
                  <VideogameAssetSharpIcon sx={{ fontSize: 100, color: "#2f2848" }} />
                  <Typography variant="h6" sx={{ fontWeight: 400, px: 1, color: "#2f2848", opacity: 0.8 }}>
                    Automati
                  </Typography>
                </Paper>
                <Paper component="a" href="/gameMixes" elevation={3} sx={{ textDecoration: "none", textAlign: "center" }}>
                  <CasinoSharpIcon sx={{ fontSize: 100, color: "#2f2848" }} />
                  <Typography variant="h6" sx={{ fontWeight: 400, px: 1, color: "#2f2848", opacity: 0.8 }}>
                    Miksevi igara
                  </Typography>
                </Paper>
                <Paper component="a" href="/games" elevation={3} sx={{ textDecoration: "none", textAlign: "center" }}>
                  <Filter7Icon sx={{ fontSize: 100, color: "#2f2848" }} />
                  <Typography variant="h6" sx={{ fontWeight: 400, px: 1, color: "#2f2848", opacity: 0.8 }}>
                    Igre
                  </Typography>
                </Paper>
                <Paper component="a" href="/news" elevation={3} sx={{ textDecoration: "none", textAlign: "center" }}>
                  <NewspaperSharpIcon sx={{ fontSize: 100, color: "#2f2848" }} />
                  <Typography variant="h6" sx={{ fontWeight: 400, px: 1, color: "#2f2848", opacity: 0.8 }}>
                    Novosti
                  </Typography>
                </Paper>
                <Paper component="a" href="/gallery" elevation={3} sx={{ textDecoration: "none", textAlign: "center" }}>
                  <CollectionsSharpIcon sx={{ fontSize: 100, color: "#2f2848" }} />
                  <Typography variant="h6" sx={{ fontWeight: 400, px: 1, color: "#2f2848", opacity: 0.8 }}>
                    Galerija
                  </Typography>
                </Paper>
                <Paper component="a" href="/categories" elevation={3} sx={{ textDecoration: "none", textAlign: "center" }}>
                  <ChecklistRtlIcon sx={{ fontSize: 100, color: "#2f2848" }} />
                  <Typography variant="h6" sx={{ fontWeight: 400, px: 1, color: "#2f2848", opacity: 0.8 }}>
                    Kategorije
                  </Typography>
                </Paper>
                <Paper component="a" href="/platforms" elevation={3} sx={{ textDecoration: "none", textAlign: "center" }}>
                  <CategoryIcon sx={{ fontSize: 100, color: "#2f2848" }} />
                  <Typography variant="h6" sx={{ fontWeight: 400, px: 1, color: "#2f2848", opacity: 0.8 }}>
                    Platforme
                  </Typography>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
export default HomePage;