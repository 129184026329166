import { Box, Button, Card, Dialog, DialogActions, DialogContent, Grid, IconButton } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useState } from "react";
import GameDialog from "./GameDialog";
import { toast } from "react-toastify";
import { api } from "../../services";

const GamesCard = ({ data, setGames, games, loadGames }) => {
  const [isEditDialogOpened, setIsEditDialogOpened] = useState(false);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);

  const deleteGame = () => {
    api.games.deleteGame(data.id).then((res) => {
      if (!res) return;
      setGames(games.filter(game => game.id !== data.id));
      setIsDeleteDialogOpened(false);
      toast.success("Igra je uspešno obrisana", { autoClose: 2000 });
    })
  }

  return (
    <Card elevation={4} sx={{ padding: "10px", height: "100%" }}>
      <Grid container justifyContent="space-between" direction='column' sx={{ height: '100%' }}>
        <Grid item xs container justifyContent="left" padding="10px">
          <Grid item>
            <Box sx={{ width: "50px" }}>
              <img src={process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + data.media.src} alt="" style={{ width: "100%" }}></img>
            </Box>
          </Grid>
          <Grid item sx={{ paddingLeft: "10px" }} xs>
            <p style={{ margin: 0 }}>{data.name}</p>
          </Grid>
        </Grid>
        <Grid item xs="auto" container sx={{ borderTop: "1px solid #ddd", pt: 1 }} justifyContent="space-between">
          <Grid item>
            <IconButton component="label" onClick={() => setIsEditDialogOpened(true)}>
              <EditIcon sx={{ color: "#2F2848" }} fontSize='medium' />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={() => setIsDeleteDialogOpened(true)}>
              <DeleteForeverIcon sx={{ color: "#2F2848" }} fontSize='medium' />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <GameDialog action="edit" data={data} isDialogOpened={isEditDialogOpened} setIsDialogOpened={setIsEditDialogOpened} loadGames={loadGames} />
      <Dialog open={isDeleteDialogOpened}>
        <DialogContent>Da li ste sigurni da želite da obrišete igru <strong>{data.name}</strong>?</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpened(false)} sx={{ color: "#2F2848" }}>Odustani</Button>
          <Button onClick={deleteGame} autoFocus variant='contained' sx={{ backgroundColor: "#2F2848", "&:hover": { backgroundColor: "#443A69" } }}>Obriši</Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}
export default GamesCard