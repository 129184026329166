import React, { forwardRef, useEffect } from "react";
import Item from "../../components/Item";
import Grid from "@mui/material/Grid";
import { Box, Button, Dialog, Slide } from "@mui/material";
import CabinetsEditor from "./CabinetEditor";
import { useState } from "react";
import { api } from "../../services";
import { toast } from "react-toastify";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CabinetsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cabinets, setCabinets] = useState([]);
  const [isPreLoaded, setIsPreLoaded] = useState(false);

  const fetchCabinets = () => {
    api.cabinets.getCabinets().then(res => {
      if (!res) return;
      setCabinets(res.filter(item => (item.status === "active" || item.status === "published")))
    })
  }

  const deleteCabinet = (cabinetId) => {
    api.cabinets.updateCabinet(cabinetId, {status: "deleted"}).then(res => {
      if(!res) return;
      toast.success('Uspešno ste obrisali automat', { autoClose: 2000 })
      fetchCabinets();
    })
  }

  useEffect(() => {
    setIsPreLoaded(true);
  }, []);

  useEffect(() => {
    if (isPreLoaded) fetchCabinets();
  }, [isPreLoaded])

  return (
    <>
      <Grid item sx={{ mb: 3 }}>
        <Button onClick={() => setIsModalOpen(true)} variant='contained' sx={{ backgroundColor: "#2F2848", "&:hover": { backgroundColor: "#443A69" } }}>Dodaj novi automat</Button>
      </Grid>
      <Grid container spacing={3}>
        {cabinets.map((item, key) => (
          <Grid xs={12} md={6} lg={4} item key={key}>
            <Item type="cabinets" data={item} loadData={fetchCabinets} deleteItem={() => deleteCabinet(item.id)}/>
          </Grid>
        ))}
      </Grid>
      <Dialog open={isModalOpen} fullScreen TransitionComponent={Transition}>
        <Box sx={{ marginTop: { xs: "80px", md: "70px" } }}>
          <CabinetsEditor action="add" setIsModalOpen={setIsModalOpen} allData={cabinets} setAllData={setCabinets}></CabinetsEditor>
        </Box>
      </Dialog>
    </>
  )
}
export default CabinetsPage