import React, { createContext, useReducer } from 'react';

export const NovoContextType = {
  SET_USER: 'SET_USER',
  SET_TOKEN: 'SET_TOKEN',
  SET_BADGE: 'SET_BADGE',
}

const token = sessionStorage.getItem('token');
const InitialState = {
  user: null,
  token: JSON.parse(token),
  badge: false
}

export const NovoContext = createContext();

const NovoReducer = (state, action) => {
  switch (action.type) {
    case NovoContextType.SET_USER: {
      return { ...state, user: action.data }
    }
    case NovoContextType.SET_TOKEN: {
      return { ...state, token: action.data }
    }
    case NovoContextType.SET_BADGE: {
      return { ...state, badge: action.data }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const NovoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NovoReducer, InitialState)
  const value = { state, dispatch }
  return <NovoContext.Provider value={value}>{children}</NovoContext.Provider>
}
